import React, { useState, useEffect } from 'react';
import './ImagesSection.css';
import ImageCards from './ImageCards';
import ImagesFilter from './ImagesFilter';
import Modal from '../UI/Modal';
import Popup from '../Popup/Popup';
import FILTER_KEYS from '../../utils/FILTER_KEYS';

function ImagesSection() {
  const [imagesData, setImagesData] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(undefined);
  const [popupIsOpened, setPopupIsOpened] = useState(false);
  const [scrollUnderPopup, setScrollUnderPopup] = useState(0);

  let selectedImageData;
  if (selectedImageIndex !== undefined) {
    selectedImageData = imagesData[+selectedImageIndex];
  }

  const openPopupHandler = (e) => {
    setScrollUnderPopup(window.scrollY);
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollUnderPopup}px`;
    e.preventDefault();
    // e.stopPropagation();
    setSelectedImageIndex(+e.currentTarget.dataset.cardIndex);
    setPopupIsOpened(true);
  };

  const closePressHandler = (e) => {
    e.preventDefault();
    setPopupIsOpened(false);
    setSelectedImageIndex(undefined);
    document.body.style.position = 'static';
    window.scrollTo(0, scrollUnderPopup);
  };

  const nextImageHandler = () => {
    if (selectedImageIndex === imagesData.length - 1) {
      setSelectedImageIndex(0);
    } else {
      setSelectedImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const previousImageHandler = () => {
    if (selectedImageIndex === 0) {
      setSelectedImageIndex(imagesData.length - 1);
    } else {
      setSelectedImageIndex((prevIndex) => prevIndex - 1);
    }
  };

  useEffect(() => {
    const escPressHandler = (e) => {
      if (e.key === 'Escape') {
        setPopupIsOpened(false);
        setSelectedImageIndex(undefined);
        document.body.style.position = 'static';
        window.scrollTo(0, scrollUnderPopup);
      }
    };
    popupIsOpened && document.addEventListener('keydown', escPressHandler);
    return () => {
      popupIsOpened && document.removeEventListener('keydown', escPressHandler);
    };
  }, [popupIsOpened, scrollUnderPopup]);

  return (
    <section className="images-section">
      <ImagesFilter filterKeys={FILTER_KEYS} onFilterSelect={setImagesData} />
      <ImageCards onImageClick={openPopupHandler} imagesData={imagesData} />

      {popupIsOpened && (
        <Modal>
          <Popup
            isOpened={popupIsOpened}
            imageData={selectedImageData}
            nextImage={nextImageHandler}
            previousImage={previousImageHandler}
            onClose={closePressHandler}
          />
        </Modal>
      )}
    </section>
  );
}

export default ImagesSection;

import React from "react";
import './WebCard.css';

function WebCard({ title, technologiesList, description, link, gitLink, linkText, technologies, features, imagePath }) {

  return (
    <li className='web-card'>
      <h2 className='web-card__title'>{title}</h2>
      <h3 className='web-card__subtitle'>{technologiesList}</h3>
      <a href={link}
        target="_blank"
        rel="noreferrer">
        <img className='web-card__image' src={imagePath} alt={'превью проекта ' + title} title='Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quia nulla neque accusantium sit eum natus, tenetur quisquam odit magnam qui nemo quod libero quo culpa quas nesciunt ut aspernatur cum.' />
        
      </a>
      <footer className='web-card__footer'>
        <a className='web-card__footer-text-link'
          href={link}
          target="_blank"
          rel="noreferrer">
          {linkText}
        </a>
        <a href={gitLink}
          className="web-card__footer-git-link"
          target="_blank"
          rel="noreferrer">
        </a>
      </footer>
    </li>
  );
}

export default WebCard;


import preview_of_pages_132_1 from '../images/image-processing/officiel-pages/of_pages_132_1_preview.jpg';
import after_of_pages_132_1 from '../images/image-processing/officiel-pages/of_pages_132_1.jpg';

import preview_of_pages_132_2 from '../images/image-processing/officiel-pages/of_pages_132_2_preview.jpg';
import after_of_pages_132_2 from '../images/image-processing/officiel-pages/of_pages_132_2.jpg';
import after_of_pages_132_3 from '../images/image-processing/officiel-pages/of_pages_132_3.jpg';
import after_of_pages_132_4 from '../images/image-processing/officiel-pages/of_pages_132_4.jpg';

import preview_of_pages_134_1 from '../images/image-processing/officiel-pages/of_pages_134_1_preview.jpg';
import after_of_pages_134_1 from '../images/image-processing/officiel-pages/of_pages_134_1.jpg';

import preview_of_pages_134_2 from '../images/image-processing/officiel-pages/of_pages_134_2_preview.jpg';
import after_of_pages_134_2 from '../images/image-processing/officiel-pages/of_pages_134_2.jpg';
import after_of_pages_133_3 from '../images/image-processing/officiel-pages/of_pages_133_3.jpg';
import after_of_pages_133_4 from '../images/image-processing/officiel-pages/of_pages_133_4.jpg';
import after_of_pages_138_2 from '../images/image-processing/officiel-pages/of_pages_138_2.jpg';
import after_of_pages_138_3 from '../images/image-processing/officiel-pages/of_pages_138_3.jpg';




const OFF_FASHION_DATA = [
  {
    title: "L'OFFICIEL",
    subtitle: 'fashion',
    preview: preview_of_pages_132_1,
    imageAfter: after_of_pages_132_1,
    alt: "разворот журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'fashion',
    preview: preview_of_pages_132_2,
    imageAfter: after_of_pages_132_2,
    alt: "разворот журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
    // hiddenPreview: true,
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'fashion',
    // preview: preview_of_pages_132_3,
    imageAfter: after_of_pages_132_3,
    alt: "разворот журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
    hiddenPreview: true,
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'fashion',
    // preview: preview_of_pages_132_4,
    imageAfter: after_of_pages_132_4,
    alt: "разворот журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
    hiddenPreview: true,
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'style',
    preview: preview_of_pages_134_1,
    imageAfter: after_of_pages_134_1,
    alt: "разворот журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'style',
    preview: preview_of_pages_134_2,
    imageAfter: after_of_pages_134_2,
    alt: "разворот журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
    // hiddenPreview: true,
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'style',
    // preview: preview_of_pages_134_2,
    imageAfter: after_of_pages_133_3,
    alt: "разворот журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
    hiddenPreview: true,
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'style',
    // preview: preview_of_pages_134_2,
    imageAfter: after_of_pages_133_4,
    alt: "разворот журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
    hiddenPreview: true,
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'style',
    // preview: preview_of_pages_134_2,
    imageAfter: after_of_pages_138_2,
    alt: "разворот журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
    hiddenPreview: true,
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'style',
    // preview: preview_of_pages_134_2,
    imageAfter: after_of_pages_138_3,
    alt: "разворот журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
    hiddenPreview: true,
  },
];

export default OFF_FASHION_DATA;

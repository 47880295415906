import './Main.css';
import { Route, Switch, Redirect } from 'react-router-dom';

import WebCards from '../WebCards/WebCards';
import About from '../About/About';
import Welcome from '../Welcome/Welcome';
import ImagesSection from '../ImagesSection/ImagesSection';

function Main() {
  return (
    <main className="content">
      <Switch>
        <Route exact path="/">
          <Welcome />
        </Route>
        <Route exact path="/web-developing">
          <div className="content__container">
            <WebCards />
          </div>
        </Route>
        <Route path="/image-processing">
          <div className="content__container">
            <ImagesSection />
          </div>
        </Route>
        <Route exact path="/about">
          <div className="content__container">
            <About />
          </div>
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </main>
  );
}

export default Main;

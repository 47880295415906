import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { imagesDoneData } from '../../utils/imagesDoneData';
import './ImagesFilter.css';

const ImagesFilter = ({
  filterKeys,
  onFilterSelect,
  defaultFilterId = 'k6',
}) => {
  const [filterId, setFilterId] = useState(defaultFilterId);

  const currentSelectedFilter = filterKeys.find(
    (filterKey) => filterKey.id === filterId
  );

  const filterSelectHandler = (selectedFilterId) => {
    const newSelectedFilter = filterKeys.find(
      (filterKey) => filterKey.id === selectedFilterId
    );
    const filteredData = imagesDoneData.filter((item) => {
      return item.tags.includes(newSelectedFilter.tag);
    });
    onFilterSelect(filteredData);
  };

  useEffect(() => {
    filterSelectHandler(filterId);
  }, []);

  return (
    <div className="filter">
      <ul className="filter__list">
        {filterKeys.map((filterKey) => (
          <li
            className={cn('filter__item', {
              filter__item_active: filterKey.id === filterId,
            })}
            key={filterKey.id}
            onClick={() => {
              setFilterId(filterKey.id);
              filterSelectHandler(filterKey.id);
            }}
          >
            {filterKey.name}
          </li>
        ))}
      </ul>
      <h2 className="image-cards__title">{currentSelectedFilter.title}</h2>
      <p className="image-cards__subtitle">{currentSelectedFilter.subtitle}</p>
      <p className="image-cards__help-text">{currentSelectedFilter.text}</p>
    </div>
  );
};

export default ImagesFilter;

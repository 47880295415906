import after1 from '../images/image-processing/after_1.jpg';
import after2 from '../images/image-processing/after_2.jpg';
import after3 from '../images/image-processing/after_3.jpg';
import after4 from '../images/image-processing/after_4.jpg';
import after5 from '../images/image-processing/after_5.jpg';
import after6 from '../images/image-processing/after_6.jpg';
import after7 from '../images/image-processing/after_7.jpg';
import after8 from '../images/image-processing/after_8.jpg';
import after9 from '../images/image-processing/after_9.jpg';
import after10 from '../images/image-processing/after_10.jpg';
import after11 from '../images/image-processing/after_11.jpg';
import after12 from '../images/image-processing/after_12.jpg';
import after13 from '../images/image-processing/after_13.jpg';
import after14 from '../images/image-processing/after_14.jpg';
import after15 from '../images/image-processing/after_15.jpg';
import after16 from '../images/image-processing/after_16.jpg';
import after17 from '../images/image-processing/after_17.jpg';
import after18 from '../images/image-processing/after_18.jpg';
import after19 from '../images/image-processing/after_19.jpg';
import after20 from '../images/image-processing/after_20.jpg';
import after21 from '../images/image-processing/after_21.jpg';
import after22 from '../images/image-processing/after_22.jpg';
import after23 from '../images/image-processing/after_23.jpg';

import preview1 from '../images/image-processing/preview_1.jpg';
import preview2 from '../images/image-processing/preview_2.jpg';
import preview3 from '../images/image-processing/preview_3.jpg';
import preview4 from '../images/image-processing/preview_4.jpg';
import preview5 from '../images/image-processing/preview_5.jpg';
import preview6 from '../images/image-processing/preview_6.jpg';
import preview7 from '../images/image-processing/preview_7.jpg';
import preview8 from '../images/image-processing/preview_8.jpg';
import preview9 from '../images/image-processing/preview_9.jpg';
import preview10 from '../images/image-processing/preview_10.jpg';
import preview11 from '../images/image-processing/preview_11.jpg';
import preview12 from '../images/image-processing/preview_12.jpg';
import preview13 from '../images/image-processing/preview_13.jpg';
import preview14 from '../images/image-processing/preview_14.jpg';
import preview15 from '../images/image-processing/preview_15.jpg';
import preview16 from '../images/image-processing/preview_16.jpg';
import preview17 from '../images/image-processing/preview_17.jpg';
import preview18 from '../images/image-processing/preview_18.jpg';
import preview19 from '../images/image-processing/preview_19.jpg';
import preview20 from '../images/image-processing/preview_20.jpg';
import preview21 from '../images/image-processing/preview_21.jpg';
import preview22 from '../images/image-processing/preview_22.jpg';
import preview23 from '../images/image-processing/preview_23.jpg';

import before1 from '../images/image-processing/before_1.jpg';
import before2 from '../images/image-processing/before_2.jpg';
import before3 from '../images/image-processing/before_3.jpg';
import before4 from '../images/image-processing/before_4.jpg';
import before5 from '../images/image-processing/before_5.jpg';
import before6 from '../images/image-processing/before_6.jpg';
import before7 from '../images/image-processing/before_7.jpg';
import before8 from '../images/image-processing/before_8.jpg';
import before9 from '../images/image-processing/before_9.jpg';
import before10 from '../images/image-processing/before_10.jpg';
import before11 from '../images/image-processing/before_11.jpg';
import before12 from '../images/image-processing/before_12.jpg';
import before13 from '../images/image-processing/before_13.jpg';
import before14 from '../images/image-processing/before_14.jpg';
import before15 from '../images/image-processing/before_15.jpg';
import before16 from '../images/image-processing/before_16.jpg';
import before17 from '../images/image-processing/before_17.jpg';
import before18 from '../images/image-processing/before_18.jpg';
import before19 from '../images/image-processing/before_19.jpg';
import before20 from '../images/image-processing/before_20.jpg';
import before21 from '../images/image-processing/before_21.jpg';
import before22 from '../images/image-processing/before_22.jpg';
import before23 from '../images/image-processing/before_23.jpg';

const INITIAL_IMAGES_DATA = [
  {
    title: 'Bork',
    subtitle: 'тестовая ретушь',
    preview: preview23,
    imageAfter: after23,
    imageBefore: before23,
    alt: 'тостер BORK',
    tags: ['before-after'],
  },
  {
    title: 'Chapurin & Ralf Ringer',
    subtitle: 'Hearst Shkulev Media',
    preview: preview16,
    imageAfter: after16,
    imageBefore: before16,
    alt: 'обувь Chapurin & Ralf Ringer',
    tags: ['before-after'],
  },
  {
    title: 'Chapurin & Ralf Ringer',
    subtitle: 'Hearst Shkulev Media',
    preview: preview17,
    imageAfter: after17,
    imageBefore: before17,
    alt: 'обувь Chapurin & Ralf Ringer',
    tags: ['before-after'],
  },
  {
    title: "O'STIN",
    subtitle: 'Hearst Shkulev Media',
    preview: preview1,
    imageAfter: after1,
    imageBefore: before1,
    alt: "обувь O'STIN",
    tags: ['before-after'],
  },
  
  {
    title: "Fashion",
    subtitle: 'shooting',
    preview: preview22,
    imageAfter: after22,
    imageBefore: before22,
    alt: "модная съемка",
    tags: ['before-after'],
  },
  
  {
    title: 'Burgener',
    subtitle: 'Comdental',
    preview: preview4,
    imageAfter: after4,
    imageBefore: before4,
    alt: "обложка каталога 'Burgener'",
    tags: ['before-after'],
  },
  {
    title: 'Домовой',
    subtitle: 'magazine',
    description: '',
    client: '',
    author: '',
    features: '',
    preview: preview7,
    imageAfter: after7,
    imageBefore: before7,
    alt: "фото из рубрики Стиль, журнал 'Домовой'",
    tags: ['before-after'],
  },
  {
    title: 'Домовой',
    subtitle: 'magazine',
    description: '',
    client: '',
    author: '',
    features: '',
    preview: preview8,
    imageAfter: after8,
    imageBefore: before8,
    alt: "фото из рубрики Стиль, журнал 'Домовой'",
    tags: ['before-after'],
    hiddenPreview: true,
  },
  {
    title: "O'STIN",
    subtitle: 'Hearst Shkulev Media',
    preview: preview19,
    imageAfter: after19,
    imageBefore: before19,
    alt: "мода O'STIN",
    tags: ['before-after'],
  },
  {
    title: "O'STIN",
    subtitle: 'Hearst Shkulev Media',
    preview: preview18,
    imageAfter: after18,
    imageBefore: before18,
    alt: "мода O'STIN",
    tags: ['before-after'],
    hiddenPreview: true,

  },
  {
    title: "O'STIN",
    subtitle: 'Hearst Shkulev Media',
    preview: preview2,
    imageAfter: after2,
    imageBefore: before2,
    alt: "купальники O'STIN",
    tags: ['before-after'],
  },
  {
    title: "O'STIN",
    subtitle: 'Hearst Shkulev Media',
    preview: preview3,
    imageAfter: after3,
    imageBefore: before3,
    alt: "купальники O'STIN",
    tags: ['before-after'],
    hiddenPreview: true,

  },
  {
    title: "O'STIN",
    subtitle: 'Hearst Shkulev Media',
    preview: preview5,
    imageAfter: after5,
    imageBefore: before5,
    alt: "купальники O'STIN",
    tags: ['before-after'],
    hiddenPreview: true,
  },
  {
    title: 'Jewelry Garden',
    subtitle: 'magazine',
    preview: preview6,
    imageAfter: after6,
    imageBefore: before6,
    alt: "обложка журнала 'Jewelry Garden'",
    tags: ['before-after'],
  },
  
  {
    title: 'Домовой',
    subtitle: 'пластика',
    description: '',
    client: '',
    author: '',
    features: '',
    preview: preview20,
    imageAfter: after20,
    imageBefore: before20,
    alt: "фото из приложения о пластической хирургии журнала 'Домовой'",
    tags: ['before-after'],
  },
  {
    title: 'Verdan',
    subtitle: 'Comdental',
    preview: preview21,
    imageAfter: after21,
    imageBefore: before21,
    alt: 'предметное фото косметики Burgener',
    tags: ['before-after'],
  },
  {
    title: 'Verdan',
    subtitle: 'Comdental',
    description: '',
    client: '',
    author: '',
    features: '',
    preview: preview9,
    imageAfter: after9,
    imageBefore: before9,
    alt: 'предметное фото дезодорантов Verdan',
    tags: ['before-after'],
  },
  {
    title: 'CEO',
    subtitle: 'magazine',
    description: '',
    client: '',
    author: '',
    features: '',
    preview: preview10,
    imageAfter: after10,
    imageBefore: before10,
    alt: 'страница из журнала CEO с графиками',
    tags: ['before-after'],
  },
  {
    title: "L'Officiel",
    subtitle: 'magazine',
    description: '',
    client: '',
    author: '',
    features: '',
    preview: preview11,
    imageAfter: after11,
    imageBefore: before11,
    alt: "страница из журнала L'Officiel с ювелиркой",
    tags: ['before-after'],
  },
  {
    title: 'Shape',
    subtitle: 'magazine',
    description: '',
    client: '',
    author: '',
    features: '',
    preview: preview12,
    imageAfter: after12,
    imageBefore: before12,
    alt: 'обложка журнала Shape',
    tags: ['before-after'],
  },
  {
    title: 'CEO',
    subtitle: 'magazine',
    description: '',
    client: '',
    author: '',
    features: '',
    preview: preview13,
    imageAfter: after13,
    imageBefore: before13,
    alt: 'коллаж из журнала CEO, посвященный автомобилям',
    tags: ['before-after'],
  },
  {
    title: 'CEO',
    subtitle: 'magazine',
    description: '',
    client: '',
    author: '',
    features: '',
    preview: preview14,
    imageAfter: after14,
    imageBefore: before14,
    alt: 'коллаж из журнала CEO, посвященный автомобилям',
    tags: ['before-after'],
  },
  {
    title: 'Домовой',
    subtitle: 'magazine',
    description: '',
    client: '',
    author: '',
    features: '',
    preview: preview15,
    imageAfter: after15,
    imageBefore: before15,
    alt: "обложка журнала 'Домовой'",
    tags: ['before-after'],
  },
  
];

export default INITIAL_IMAGES_DATA;

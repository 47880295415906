import preview_of_pages_137_1 from '../images/image-processing/officiel-pages/of_pages_137_1_preview.jpg';
import after_of_pages_137_1 from '../images/image-processing/officiel-pages/of_pages_137_1.jpg';

import preview_of_pages_137_2 from '../images/image-processing/officiel-pages/of_pages_137_2_preview.jpg';
import after_of_pages_137_2 from '../images/image-processing/officiel-pages/of_pages_137_2.jpg';

import preview_of_pages_137_3 from '../images/image-processing/officiel-pages/of_pages_137_3_preview.jpg';
import after_of_pages_137_3 from '../images/image-processing/officiel-pages/of_pages_137_3.jpg';
import after_of_pages_137_4 from '../images/image-processing/officiel-pages/of_pages_137_4.jpg';


const OFF_WHATCHES_DATA = [
  {
    title: "L'OFFICIEL",
    subtitle: 'whatches',
    preview: preview_of_pages_137_1,
    imageAfter: after_of_pages_137_1,
    alt: "разворот журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'фото Влад Антонов',
    preview: preview_of_pages_137_2,
    imageAfter: after_of_pages_137_2,
    alt: "разворот журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
    hiddenPreview: true,
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'фото Влад Антонов',
    preview: preview_of_pages_137_3,
    imageAfter: after_of_pages_137_3,
    alt: "разворот журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
    hiddenPreview: true,
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'фото Влад Антонов',
    // preview: preview_of_pages_137_4,
    imageAfter: after_of_pages_137_4,
    alt: "разворот журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
    hiddenPreview: true,
  },
];

export default OFF_WHATCHES_DATA;

const FILTER_KEYS = [
  {
    id: 'k1',
    name: "L'OFFICIEL",
    tag: "L'OFFICIEL",
    title: "L'OFFICIEL",
    text: '(ретушь, цветокоррекция, допечатная подготовка журнала 200+ страниц в течение года)',
    subtitle: 'Арт-директор: Ольга Скорупская',
  },
  {
    id: 'k9',
    name: 'предметка',
    tag: 'subject',
    title: 'Предметная съемка',
    subtitle: 'Обработанные предметные фотографии',
    text: '(цветокоррекция, ретушь)',
  },
  {
    id: 'k4',
    name: 'CEO',
    tag: 'CEO',
    title: 'CEO',
    subtitle: 'Арт-директоры: Рома Манихин, Митя Девишвили, Даша Елизарова',
    text: '(ретушь, цветокоррекция, допечатная подготовка журнала в течение нескольких лет)',
  },

  {
    id: 'k5',
    name: 'стиль',
    tag: 'style',
    title: 'Style',
    subtitle: 'Некоторые страницы из журналов, посвященные моде и стилю',
    text: '(цветокоррекция, ретушь, обтравки, подготовка к печати)',
  },
  {
    id: 'k2',
    name: 'beauty',
    tag: 'beauty',
    title: 'Beauty',
    subtitle: 'Некоторые страницы из журналов, посвященные красоте',
    text: '(цветокоррекция, ретушь, обтравки, подготовка к печати)',
  },
  {
    id: 'k3',
    name: 'коллажи',
    tag: 'collage',
    title: 'Коллажи',
    subtitle: 'выполнены для журналов по проектам арт-директора',
  },
  // {id: 'k7', name: "портреты"},
  // {id: 'k8', name: "Comdental"},
  
  // {id: 'k10', name: "мода", tag: "fashion"},
  {
    id: 'k11',
    name: 'обложки',
    tag: 'cover',
    title: 'Обложки',
    subtitle: 'Некоторые обложки подготовленных к печати журналов',
  },

  {
    id: 'k6',
    name: 'до и после',
    tag: 'before-after',
    title: 'Процесс',
    subtitle: 'Несколько примеров изображений до и после обработки',
    text: '(кликните по изображению, чтобы увидеть оригинал)',
  },
  // {
  //   id: 'k12',
  //   name: 'Лодка',
  //   tag: 'lodka',
  //   title: 'Лодка',
  //   subtitle: 'Арт-директор: Ольга Скорупская',
  //   text: '(все работы по ретуши допечатной подготовке журнала)',
  // },
];

export default FILTER_KEYS;

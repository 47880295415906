// CEO covers
import peview_ceo_cover_5_2010 from '../images/image-processing/CEO-covers/ceo_cover_5-2010_preview.jpg';
import after_ceo_cover_5_2010 from '../images/image-processing/CEO-covers/ceo_cover_5-2010.jpg';

import peview_ceo_cover_9_2010 from '../images/image-processing/CEO-covers/ceo_cover_9-2010_preview.jpg';
import after_ceo_cover_9_2010 from '../images/image-processing/CEO-covers/ceo_cover_9-2010.jpg';

import peview_ceo_cover_8_2010 from '../images/image-processing/CEO-covers/ceo_cover_8-2010_preview.jpg';
import after_ceo_cover_8_2010 from '../images/image-processing/CEO-covers/ceo_cover_8-2010.jpg';

import peview_ceo_cover_10_2010 from '../images/image-processing/CEO-covers/ceo_cover_10-2010_preview.jpg';
import after_ceo_cover_10_2010 from '../images/image-processing/CEO-covers/ceo_cover_10-2010.jpg';

import peview_ceo_cover_10_2009 from '../images/image-processing/CEO-covers/ceo_cover_10-2009_preview.jpg';
import after_ceo_cover_10_2009 from '../images/image-processing/CEO-covers/ceo_cover_10-2009.jpg';

import peview_ceo_cover_11_2009 from '../images/image-processing/CEO-covers/ceo_cover_11-2009_preview.jpg';
import after_ceo_cover_11_2009 from '../images/image-processing/CEO-covers/ceo_cover_11-2009.jpg';

import peview_ceo_cover_12_2009 from '../images/image-processing/CEO-covers/ceo_cover_12-2009_preview.jpg';
import after_ceo_cover_12_2009 from '../images/image-processing/CEO-covers/ceo_cover_12-2009.jpg';

import peview_ceo_cover_1_2011 from '../images/image-processing/CEO-covers/ceo_cover_1-2011_preview.jpg';
import after_ceo_cover_1_2011 from '../images/image-processing/CEO-covers/ceo_cover_1-2011.jpg';


const CEO_COVERS_DATA = [
  {
    title: 'CEO',
    subtitle: 'magazine',
    preview: peview_ceo_cover_10_2009,
    imageAfter: after_ceo_cover_10_2009,
    alt: 'обложка CEO #10 2009',
    tags: ['CEO', 'cover'],
  },
  {
    title: 'CEO',
    subtitle: 'magazine',
    preview: peview_ceo_cover_11_2009,
    imageAfter: after_ceo_cover_11_2009,
    alt: 'обложка CEO #11 2009',
    tags: ['CEO', 'cover'],
  },
  {
    title: 'CEO',
    subtitle: 'magazine',
    preview: peview_ceo_cover_12_2009,
    imageAfter: after_ceo_cover_12_2009,
    alt: 'обложка CEO #12 2009',
    tags: ['CEO', 'cover'],
  },
  {
    title: 'CEO',
    subtitle: 'magazine',
    preview: peview_ceo_cover_5_2010,
    imageAfter: after_ceo_cover_5_2010,
    alt: 'обложка CEO #5 2010',
    tags: ['CEO', 'cover'],
  },
  {
    title: 'CEO',
    subtitle: 'magazine',
    preview: peview_ceo_cover_8_2010,
    imageAfter: after_ceo_cover_8_2010,
    alt: 'обложка CEO #8 2010',
    tags: ['CEO', 'cover'],
  },
  {
    title: 'CEO',
    subtitle: 'magazine',
    preview: peview_ceo_cover_9_2010,
    imageAfter: after_ceo_cover_9_2010,
    alt: 'обложка CEO #9 2010',
    tags: ['CEO', 'cover'],
  },
  {
    title: 'CEO',
    subtitle: 'magazine',
    preview: peview_ceo_cover_10_2010,
    imageAfter: after_ceo_cover_10_2010,
    alt: 'обложка CEO #10 2010',
    tags: ['CEO', 'cover'],
  },
  {
    title: 'CEO',
    subtitle: 'magazine',
    preview: peview_ceo_cover_1_2011,
    imageAfter: after_ceo_cover_1_2011,
    alt: 'обложка CEO #1 2011',
    tags: ['CEO', 'cover'],
  },
];

export default CEO_COVERS_DATA;

import preview_vld_hol_01 from '../images/image-processing/vlad/vld-hol_01_preview.jpg';
import preview_vld_hol_02 from '../images/image-processing/vlad/vld-hol_02_preview.jpg';
import preview_vld_hol_03 from '../images/image-processing/vlad/vld-hol_03_preview.jpg';
import preview_vld_hol_04 from '../images/image-processing/vlad/vld-hol_04_preview.jpg';
import preview_vld_hol_05 from '../images/image-processing/vlad/vld-hol_05_preview.jpg';
import preview_vld_hol_06 from '../images/image-processing/vlad/vld-hol_06_preview.jpg';
import preview_vld_hol_07 from '../images/image-processing/vlad/vld-hol_07_preview.jpg';
import preview_vld_hol_08 from '../images/image-processing/vlad/vld-hol_08_preview.jpg';
import preview_vld_hol_09 from '../images/image-processing/vlad/vld-hol_09_preview.jpg';
import vld_hol_01 from '../images/image-processing/vlad/vld-hol_01.jpg';
import vld_hol_02 from '../images/image-processing/vlad/vld-hol_02.jpg';
import vld_hol_03 from '../images/image-processing/vlad/vld-hol_03.jpg';
import vld_hol_04 from '../images/image-processing/vlad/vld-hol_04.jpg';
import vld_hol_05 from '../images/image-processing/vlad/vld-hol_05.jpg';
import vld_hol_06 from '../images/image-processing/vlad/vld-hol_06.jpg';
import vld_hol_07 from '../images/image-processing/vlad/vld-hol_07.jpg';
import vld_hol_08 from '../images/image-processing/vlad/vld-hol_08.jpg';
import vld_hol_09 from '../images/image-processing/vlad/vld-hol_09.jpg';


const VLAD_HOLLAND_DATA = [
  {
    title: 'Comdental',
    subtitle: 'Влад Антонов',
    preview: preview_vld_hol_01,
    imageAfter: vld_hol_01,
    alt: 'предметная съемка для Comdental',
    tags: ['subject', 'vlad'],
  },
  {
    title: 'Comdental',
    subtitle: 'Влад Антонов',
    preview: preview_vld_hol_02,
    imageAfter: vld_hol_02,
    alt: 'предметная съемка для Comdental',
    tags: ['subject', 'vlad'],
  },
  {
    title: 'Comdental',
    subtitle: 'Влад Антонов',
    preview: preview_vld_hol_03,
    imageAfter: vld_hol_03,
    alt: 'предметная съемка для Comdental',
    tags: ['subject', 'vlad'],
  },
  {
    title: 'Comdental',
    subtitle: 'Влад Антонов',
    preview: preview_vld_hol_04,
    imageAfter: vld_hol_04,
    alt: 'предметная съемка для Comdental',
    tags: ['subject', 'vlad'],
  },
  {
    title: 'Comdental',
    subtitle: 'Влад Антонов',
    preview: preview_vld_hol_05,
    imageAfter: vld_hol_05,
    alt: 'предметная съемка для Comdental',
    tags: ['subject', 'vlad'],
  },
  {
    title: 'Comdental',
    subtitle: 'Влад Антонов',
    preview: preview_vld_hol_06,
    imageAfter: vld_hol_06,
    alt: 'предметная съемка для Comdental',
    tags: ['subject', 'vlad'],
  },
  {
    title: 'Comdental',
    subtitle: 'Влад Антонов',
    preview: preview_vld_hol_07,
    imageAfter: vld_hol_07,
    alt: 'предметная съемка для Comdental',
    tags: ['subject', 'vlad'],
    hiddenPreview: true,
  },
  {
    title: 'Comdental',
    subtitle: 'Влад Антонов',
    preview: preview_vld_hol_08,
    imageAfter: vld_hol_08,
    alt: 'предметная съемка для Comdental',
    tags: ['subject', 'vlad'],
    hiddenPreview: true,
  },
  {
    title: 'Comdental',
    subtitle: 'Влад Антонов',
    preview: preview_vld_hol_09,
    imageAfter: vld_hol_09,
    alt: 'предметная съемка для Comdental',
    tags: ['subject', 'vlad'],
    hiddenPreview: true,
  },
];

export default VLAD_HOLLAND_DATA;

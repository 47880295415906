import './Welcome.css';
import SocialBlock from '../SocialBlock/SocialBlock';

function Welcome() {
  return (
    <section className="welcome">
      <div>
        <h1 className="welcome__title">NIKOLAY KVASOV</h1>
        <h2 className="welcome__subtitle">
          Frontend&nbsp;Developer, Image&nbsp;Retoucher
        </h2>
      </div>
      <div className="welcome__footer">
        <p className="welcome__copyright">Фото &copy;&nbsp;Ольга Скорупская</p>
        <SocialBlock iconSize="30px" />
      </div>
    </section>
  );
}

export default Welcome;

import React from 'react';

import './ImageCard.css';

const ImageCard = ({ card, onClick, index }) => {
  return (
    <li
      // key={card.preview}
      className="image-card"
      data-card-index={index}
      onClick={onClick}
    >
      <img className="image-card__image" src={card.preview} alt={card.alt} />
      <div className="image-card__info">
        <h3 className="image-card__title">{card.title} </h3>
        <p className="image-card__subtitle">{card.subtitle}</p>
      </div>
    </li>
  );
};

export default ImageCard;

import preview_of_cover_132 from '../images/image-processing/officiel-covers/of_cover_132_preview.jpg';
import after_of_cover_132 from '../images/image-processing/officiel-covers/of_cover_132.jpg';

import preview_of_cover_133 from '../images/image-processing/officiel-covers/of_cover_133_preview.jpg';
import after_of_cover_133 from '../images/image-processing/officiel-covers/of_cover_133.jpg';

import preview_of_cover_134 from '../images/image-processing/officiel-covers/of_cover_134_preview.jpg';
import after_of_cover_134 from '../images/image-processing/officiel-covers/of_cover_134.jpg';

import preview_of_cover_135 from '../images/image-processing/officiel-covers/of_cover_135_preview.jpg';
import after_of_cover_135 from '../images/image-processing/officiel-covers/of_cover_135.jpg';

import preview_of_cover_136 from '../images/image-processing/officiel-covers/of_cover_136_preview.jpg';
import after_of_cover_136 from '../images/image-processing/officiel-covers/of_cover_136.jpg';

import preview_of_cover_137 from '../images/image-processing/officiel-covers/of_cover_137_preview.jpg';
import after_of_cover_137 from '../images/image-processing/officiel-covers/of_cover_137.jpg';

import preview_of_cover_138 from '../images/image-processing/officiel-covers/of_cover_138_preview.jpg';
import after_of_cover_138 from '../images/image-processing/officiel-covers/of_cover_138.jpg';

import preview_of_cover_139 from '../images/image-processing/officiel-covers/of_cover_139_preview.jpg';
import after_of_cover_139 from '../images/image-processing/officiel-covers/of_cover_139.jpg';


const OFF_COVERS_DATA = [
  {
    title: "L'OFFICIEL",
    subtitle: 'magazine',
    preview: preview_of_cover_132,
    imageAfter: after_of_cover_132,
    alt: "обложка L'OFFICIEL 132",
    tags: ["L'OFFICIEL", 'cover', 'fashion'],
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'magazine',
    preview: preview_of_cover_133,
    imageAfter: after_of_cover_133,
    alt: "обложка L'OFFICIEL 133",
    tags: ["L'OFFICIEL", 'cover', 'fashion'],
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'magazine',
    preview: preview_of_cover_134,
    imageAfter: after_of_cover_134,
    alt: "обложка L'OFFICIEL 134",
    tags: ["L'OFFICIEL", 'cover', 'fashion'],
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'magazine',
    preview: preview_of_cover_135,
    imageAfter: after_of_cover_135,
    alt: "обложка L'OFFICIEL 135",
    tags: ["L'OFFICIEL", 'cover', 'fashion'],
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'magazine',
    preview: preview_of_cover_136,
    imageAfter: after_of_cover_136,
    alt: "обложка L'OFFICIEL 136",
    tags: ["L'OFFICIEL", 'cover', 'fashion'],
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'magazine',
    preview: preview_of_cover_137,
    imageAfter: after_of_cover_137,
    alt: "обложка L'OFFICIEL 137",
    tags: ["L'OFFICIEL", 'cover', 'fashion'],
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'magazine',
    preview: preview_of_cover_138,
    imageAfter: after_of_cover_138,
    alt: "обложка L'OFFICIEL 138",
    tags: ["L'OFFICIEL", 'cover', 'fashion'],
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'magazine',
    preview: preview_of_cover_139,
    imageAfter: after_of_cover_139,
    alt: "обложка L'OFFICIEL 139",
    tags: ["L'OFFICIEL", 'cover', 'fashion'],
  },
];

export default OFF_COVERS_DATA;

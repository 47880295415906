import of_ufo_1 from '../images/image-processing/officiel-UFO/of_ufo_1.jpg';
import of_ufo_1_preview from '../images/image-processing/officiel-UFO/of_ufo_1_preview.jpg';
import of_ufo_2 from '../images/image-processing/officiel-UFO/of_ufo_2.jpg';
import of_ufo_3 from '../images/image-processing/officiel-UFO/of_ufo_3.jpg';
import of_ufo_4 from '../images/image-processing/officiel-UFO/of_ufo_4.jpg';

const OFF_UFO_DATA = [
  {
    title: "L'OFFICIEL",
    subtitle: 'beauty UFO',
    preview: of_ufo_1_preview,
    imageAfter: of_ufo_1,
    alt: "разворот журнала L'OFFICIEL – коллаж косметика в природных ландшафтах",
    tags: ["L'OFFICIEL", 'beauty', 'collage'],
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'beauty UFO',
    // preview: of_ufo_1_preview,
    imageAfter: of_ufo_2,
    alt: "разворот журнала L'OFFICIEL – коллаж косметика в природных ландшафтах",
    tags: ["L'OFFICIEL", 'beauty', 'collage'],
    hiddenPreview: true,
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'beauty UFO',
    // preview: of_ufo_1_preview,
    imageAfter: of_ufo_3,
    alt: "разворот журнала L'OFFICIEL – коллаж косметика в природных ландшафтах",
    tags: ["L'OFFICIEL", 'beauty', 'collage'],
    hiddenPreview: true,
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'beauty UFO',
    // preview: of_ufo_1_preview,
    imageAfter: of_ufo_4,
    alt: "разворот журнала L'OFFICIEL – коллаж косметика в природных ландшафтах",
    tags: ["L'OFFICIEL", 'beauty', 'collage'],
    hiddenPreview: true,
  },
  
];

export default OFF_UFO_DATA;

import React from 'react';

import './ImageCards.css';
import ImageCard from './ImageCard';

function ImageCards({ onImageClick, imagesData }) {
  return (
    <div className="image-cards">
      <ul className="image-cards__list">
        {imagesData.map(
          (imageData, index) =>
            !imageData.hiddenPreview && (
              <ImageCard
                card={imageData}
                index={index}
                onClick={onImageClick}
                key={imageData.preview}
              />
            )
        )}
      </ul>
    </div>
  );
}

export default ImageCards;

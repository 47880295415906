
import dom_plastic_1 from '../images/image-processing/domovoy-plastic/dom_plastic1.jpg';
import preview_dom_plastic_1 from '../images/image-processing/domovoy-plastic/dom_plastic1_preview.jpg';
import dom_plastic_2 from '../images/image-processing/domovoy-plastic/dom_plastic2.jpg';
import dom_plastic_3 from '../images/image-processing/domovoy-plastic/dom_plastic3.jpg';
import dom_plastic_4 from '../images/image-processing/domovoy-plastic/dom_plastic4.jpg';
import dom_plastic_5 from '../images/image-processing/domovoy-plastic/dom_plastic5.jpg';

import dom_plastic_11 from '../images/image-processing/domovoy-plastic/dom_plastic11.jpg';
import dom_plastic_12 from '../images/image-processing/domovoy-plastic/dom_plastic12.jpg';
import preview_dom_plastic_12 from '../images/image-processing/domovoy-plastic/dom_plastic12_preview.jpg';
import dom_plastic_13 from '../images/image-processing/domovoy-plastic/dom_plastic13.jpg';
import dom_plastic_14 from '../images/image-processing/domovoy-plastic/dom_plastic14.jpg';
import dom_plastic_15 from '../images/image-processing/domovoy-plastic/dom_plastic15.jpg';

import dom_lak_1 from '../images/image-processing/domovoy-plastic/dom_lak1.jpg';
import dom_lak_2 from '../images/image-processing/domovoy-plastic/dom_lak2.jpg';
import dom_lak_2_preview from '../images/image-processing/domovoy-plastic/dom_lak2_preview.jpg';

export const DOMOVOY_LAK = [
  {
    title: 'ДОМОВОЙ',
    subtitle: 'beauty: лаки',
    imageAfter: dom_lak_2,
    preview: dom_lak_2_preview,
    alt: 'фото из журнала "ДОМОВОЙ" – лаки 1',
    tags: ['beauty'],
  },
  {
    title: 'ДОМОВОЙ',
    subtitle: 'beauty: лаки',
    imageAfter: dom_lak_1,
    alt: 'фото из журнала "ДОМОВОЙ" – лаки 2',
    tags: ['beauty'],
    hiddenPreview: true,
  },
  
  
];


export const DOMOVOY_PLASTIC1 = [
  {
    title: 'ДОМОВОЙ',
    subtitle: 'пластика',
    imageAfter: dom_plastic_12,
    preview: preview_dom_plastic_12,
    alt: 'приложение к журналу "ДОМОВОЙ" о пластической хирургии',
    tags: ['beauty'],
    // hiddenPreview: true,
  },
  {
    title: 'ДОМОВОЙ',
    subtitle: 'пластика',
    imageAfter: dom_plastic_11,
    alt: 'приложение к журналу "ДОМОВОЙ" о пластической хирургии',
    tags: ['beauty'],
    hiddenPreview: true,
  },
  {
    title: 'ДОМОВОЙ',
    subtitle: 'пластика',
    imageAfter: dom_plastic_13,
    alt: 'приложение к журналу "ДОМОВОЙ" о пластической хирургии',
    tags: ['beauty'],
    hiddenPreview: true,
  },
  {
    title: 'ДОМОВОЙ',
    subtitle: 'пластика',
    imageAfter: dom_plastic_14,
    alt: 'приложение к журналу "ДОМОВОЙ" о пластической хирургии',
    tags: ['beauty'],
    hiddenPreview: true,
  },
  {
    title: 'ДОМОВОЙ',
    subtitle: 'пластика',
    imageAfter: dom_plastic_15,
    alt: 'приложение к журналу "ДОМОВОЙ" о пластической хирургии',
    tags: ['beauty'],
    hiddenPreview: true,
  },
];


export const DOMOVOY_PLASTIC = [
  {
    title: 'ДОМОВОЙ',
    subtitle: 'пластика',
    preview: preview_dom_plastic_1,
    imageAfter: dom_plastic_1,
    alt: 'приложение к журналу "ДОМОВОЙ" о пластической хирургии',
    tags: ['beauty'],
  },
  {
    title: 'ДОМОВОЙ',
    subtitle: 'пластика',
    imageAfter: dom_plastic_2,
    alt: 'приложение к журналу "ДОМОВОЙ" о пластической хирургии',
    tags: ['beauty'],
    hiddenPreview: true,
  },
  {
    title: 'ДОМОВОЙ',
    subtitle: 'пластика',
    imageAfter: dom_plastic_3,
    alt: 'приложение к журналу "ДОМОВОЙ" о пластической хирургии',
    tags: ['beauty'],
    hiddenPreview: true,
  },
  {
    title: 'ДОМОВОЙ',
    subtitle: 'пластика',
    imageAfter: dom_plastic_4,
    alt: 'приложение к журналу "ДОМОВОЙ" о пластической хирургии',
    tags: ['beauty'],
    hiddenPreview: true,
  },
  {
    title: 'ДОМОВОЙ',
    subtitle: 'пластика',
    imageAfter: dom_plastic_5,
    alt: 'приложение к журналу "ДОМОВОЙ" о пластической хирургии',
    tags: ['beauty'],
    hiddenPreview: true,
  },
];


import of_circus_131_1 from '../images/image-processing/officiel-circus/of_circus_131_1.jpg';
import preview_of_circus_131_1 from '../images/image-processing/officiel-circus/of_circus_131_1_preview.jpg';
import of_circus_131_2 from '../images/image-processing/officiel-circus/of_circus_131_2.jpg';
import of_circus_131_3 from '../images/image-processing/officiel-circus/of_circus_131_3.jpg';
import of_circus_131_4 from '../images/image-processing/officiel-circus/of_circus_131_4.jpg';


const OFF_CIRCUS_DATA = [
  {
    title: "L'OFFICIEL",
    subtitle: 'jewelry shooting',
    preview: preview_of_circus_131_1,
    imageAfter: of_circus_131_1,
    alt: "разворот журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'jewelry shooting',
    // preview: preview_of_circus_131_1,
    imageAfter: of_circus_131_2,
    alt: "разворот журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
    hiddenPreview: true,
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'jewelry shooting',
    // preview: preview_of_circus_131_1,
    imageAfter: of_circus_131_3,
    alt: "разворот журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
    hiddenPreview: true,
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'jewelry shooting',
    // preview: preview_of_circus_131_1,
    imageAfter: of_circus_131_4,
    alt: "разворот журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
    hiddenPreview: true,
  },
  
];

export default OFF_CIRCUS_DATA;

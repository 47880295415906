import preview_of_pages_133_1 from '../images/image-processing/officiel-pages/of_pages_133_1_preview.jpg';
import after_of_pages_133_1 from '../images/image-processing/officiel-pages/of_pages_133_1.jpg';

import preview_of_pages_133_2 from '../images/image-processing/officiel-pages/of_pages_133_2_preview.jpg';
import after_of_pages_133_2 from '../images/image-processing/officiel-pages/of_pages_133_2.jpg';

import preview_of_pages_136_1 from '../images/image-processing/officiel-pages/of_pages_136_1_preview.jpg';
import after_of_pages_136_1 from '../images/image-processing/officiel-pages/of_pages_136_1.jpg';

import preview_of_pages_136_2 from '../images/image-processing/officiel-pages/of_pages_136_2_preview.jpg';
import after_of_pages_136_2 from '../images/image-processing/officiel-pages/of_pages_136_2.jpg';

import preview_of_pages_138_1 from '../images/image-processing/officiel-pages/of_pages_138_1_preview.jpg';
import after_of_pages_138_1 from '../images/image-processing/officiel-pages/of_pages_138_1.jpg';

import preview_of_pages_139_1 from '../images/image-processing/officiel-pages/of_pages_139_1_preview.jpg';
import after_of_pages_139_1 from '../images/image-processing/officiel-pages/of_pages_139_1.jpg';

const OFF_BEAUTY_DATA = [
  {
    title: "L'OFFICIEL",
    subtitle: 'beauty',
    preview: preview_of_pages_133_1,
    imageAfter: after_of_pages_133_1,
    alt: "разворот бьюти журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'beauty'],
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'beauty',
    preview: preview_of_pages_136_1,
    imageAfter: after_of_pages_136_1,
    alt: "разворот бьюти журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'beauty'],
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'beauty',
    preview: preview_of_pages_136_2,
    imageAfter: after_of_pages_136_2,
    alt: "разворот бьюти журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'beauty'],
    hiddenPreview: true,
  },

  {
    title: "L'OFFICIEL",
    subtitle: 'beauty',
    preview: preview_of_pages_133_2,
    imageAfter: after_of_pages_133_2,
    alt: "разворот бьюти журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'beauty'],
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'beauty',
    preview: preview_of_pages_138_1,
    imageAfter: after_of_pages_138_1,
    alt: "разворот бьюти журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'beauty'],
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'beauty',
    preview: preview_of_pages_139_1,
    imageAfter: after_of_pages_139_1,
    alt: "разворот бьюти журнала L'OFFICIEL",
    tags: ["L'OFFICIEL", 'beauty'],
  },
];

export default OFF_BEAUTY_DATA;

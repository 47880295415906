import { useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { Link, NavLink, useLocation } from 'react-router-dom';
import './Header.css';

function Header() {
  const [menuIsExpanded, setMenuIsExpanded] = useState(false);
  const [headerIsHidden, setHeaderIsHidden] = useState(false);
  const location = useLocation();
  const locationIsIndex = location.pathname === '/';
  const linkClass = cn('nav-bar__link', {
    'nav-bar__link_location_index': locationIsIndex && !menuIsExpanded,
  });
  const headerClass = cn(
    'header',
    { header_location_index: locationIsIndex },
    { header_hidden: headerIsHidden }
  );
  const headerTitleClass = cn('header__title', {
    header__title_hidden: locationIsIndex,
  });
  const burgerClass = cn('header__burger', {
    header__burger_light: location.pathname === '/' && !menuIsExpanded,
    header__burger_active: menuIsExpanded,
  });
  const navClass = cn('header__nav-bar', {
    'header__nav-bar_expanded': menuIsExpanded,
  });

  const startScrollPos = useRef(0);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    function autoHideHeader() {
      let windowY = window.scrollY;
      if (headerIsHidden) {
        if (windowY > startScrollPos.current) {
          startScrollPos.current = windowY;
        } else if (startScrollPos.current - windowY > 10) {
          startScrollPos.current = windowY;
          setHeaderIsHidden(false);
        }
      } else {
        if (windowY < startScrollPos.current) {
          startScrollPos.current = windowY;
        } else if (windowY - startScrollPos.current > 80) {
          startScrollPos.current = windowY;
          setHeaderIsHidden(true);
        }
      }
    }

    document.addEventListener('scroll', autoHideHeader);
    return () => {
      document.removeEventListener('scroll', autoHideHeader);
    };
  }, [headerIsHidden]);

  return (
    <header className={headerClass}>
      <div className="header__content">
        <Link to="/" className="header__title-link">
          <h1 className={headerTitleClass}>NIKOLAY KVASOV</h1>
        </Link>

        <div
          className={burgerClass}
          onClick={() => {
            setMenuIsExpanded(!menuIsExpanded);
          }}
        >
          <span className="header__burger-content" />
        </div>
        <nav
          className={navClass}
          onClick={(e) => {
            setMenuIsExpanded(false);
          }}
        >
          <ul className="nav-bar__links">
            <li className="nav-bar__link-container">
              <NavLink
                exact
                to="/web-developing"
                className={linkClass}
                activeClassName="nav-bar__link_active"
                onClick={scrollToTop}
              >
                web-разработка
              </NavLink>
            </li>
            <li className="nav-bar__link-container">
              <NavLink
                exact
                to="/image-processing"
                className={linkClass}
                activeClassName="nav-bar__link_active"
                onClick={scrollToTop}
              >
                работа с изображениями
              </NavLink>
            </li>
            <li className="nav-bar__link-container">
              <NavLink
                to="/about"
                className={linkClass}
                activeClassName="nav-bar__link_active"
                onClick={scrollToTop}
              >
                обо мне
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;

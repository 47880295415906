import chapurin_1 from '../images/image-processing/chapurin/chapurin_1.jpg';
// import chapurin_1_preview from '../images/image-processing/chapurin/chapurin_1_preview.jpg';
import chapurin_2 from '../images/image-processing/chapurin/chapurin_2.jpg';
// import chapurin_2_preview from '../images/image-processing/chapurin/chapurin_2_preview.jpg';
import chapurin_3_preview from '../images/image-processing/chapurin/chapurin_3_preview.jpg';
import chapurin_4_preview from '../images/image-processing/chapurin/chapurin_4_preview.jpg';
import chapurin_9_preview from '../images/image-processing/chapurin/chapurin_9_preview.jpg';
import chapurin_3 from '../images/image-processing/chapurin/chapurin_3.jpg';
import chapurin_4 from '../images/image-processing/chapurin/chapurin_4.jpg';
import chapurin_5 from '../images/image-processing/chapurin/chapurin_5.jpg';
import chapurin_6 from '../images/image-processing/chapurin/chapurin_6.jpg';
import chapurin_7 from '../images/image-processing/chapurin/chapurin_7.jpg';
import chapurin_8 from '../images/image-processing/chapurin/chapurin_8.jpg';
import chapurin_9 from '../images/image-processing/chapurin/chapurin_9.jpg';

export const CHAPURIN_DATA = [
  {
    title: 'Chapurin & Ralf Ringer',
    subtitle: 'Hearst Shkulev Media',
    preview: chapurin_3_preview,
    imageAfter: chapurin_3,
    alt: 'кроссовки Chapurin & Ralf Ringer',
    tags: ['subject'],
    // hiddenPreview: true,
  },
  {
    title: 'Chapurin & Ralf Ringer',
    subtitle: 'Hearst Shkulev Media',
    preview: chapurin_4_preview,
    imageAfter: chapurin_4,
    alt: 'кроссовки Chapurin & Ralf Ringer',
    tags: ['subject'],
    // hiddenPreview: true,
  },
  {
    title: 'Chapurin & Ralf Ringer',
    subtitle: 'Hearst Shkulev Media',
    preview: chapurin_9_preview,
    imageAfter: chapurin_9,
    alt: 'кроссовки Chapurin & Ralf Ringer',
    tags: ['subject'],
    // hiddenPreview: true,
  },
  {
    title: 'Chapurin & Ralf Ringer',
    subtitle: 'Hearst Shkulev Media',
    // preview: chapurin_1_preview,
    imageAfter: chapurin_1,
    alt: 'кроссовки Chapurin & Ralf Ringer',
    tags: ['subject'],
    hiddenPreview: true,
  },
  {
    title: 'Chapurin & Ralf Ringer',
    subtitle: 'Hearst Shkulev Media',
    // preview: chapurin_2_preview,
    imageAfter: chapurin_2,
    alt: 'кроссовки Chapurin & Ralf Ringer',
    tags: ['subject'],
    hiddenPreview: true,
  },
  {
    title: 'Chapurin & Ralf Ringer',
    subtitle: 'Hearst Shkulev Media',
    // preview: chapurin_1_preview,
    imageAfter: chapurin_5,
    alt: 'кроссовки Chapurin & Ralf Ringer',
    tags: ['subject'],
    hiddenPreview: true,
  },
  {
    title: 'Chapurin & Ralf Ringer',
    subtitle: 'Hearst Shkulev Media',
    // preview: chapurin_1_preview,
    imageAfter: chapurin_6,
    alt: 'кроссовки Chapurin & Ralf Ringer',
    tags: ['subject'],
    hiddenPreview: true,
  },
  {
    title: 'Chapurin & Ralf Ringer',
    subtitle: 'Hearst Shkulev Media',
    // preview: chapurin_1_preview,
    imageAfter: chapurin_7,
    alt: 'кроссовки Chapurin & Ralf Ringer',
    tags: ['subject'],
    hiddenPreview: true,
  },
  {
    title: 'Chapurin & Ralf Ringer',
    subtitle: 'Hearst Shkulev Media',
    // preview: chapurin_1_preview,
    imageAfter: chapurin_8,
    alt: 'кроссовки Chapurin & Ralf Ringer',
    tags: ['subject'],
    hiddenPreview: true,
  },
];

import of_lanvin_132_1 from '../images/image-processing/officiel-lanvin/of_lanvin_132_1.jpg';
import preview_of_lanvin_132_1 from '../images/image-processing/officiel-lanvin/of_lanvin_132_1_preview.jpg';
import of_lanvin_132_2 from '../images/image-processing/officiel-lanvin/of_lanvin_132_2.jpg';
import of_lanvin_132_3 from '../images/image-processing/officiel-lanvin/of_lanvin_132_3.jpg';
import of_lanvin_132_4 from '../images/image-processing/officiel-lanvin/of_lanvin_132_4.jpg';
import of_lanvin_132_5 from '../images/image-processing/officiel-lanvin/of_lanvin_132_5.jpg';
import of_lanvin_132_6 from '../images/image-processing/officiel-lanvin/of_lanvin_132_6.jpg';
import of_lanvin_132_7 from '../images/image-processing/officiel-lanvin/of_lanvin_132_7.jpg';



const OFF_LANVIN_DATA = [
  {
    title: "L'OFFICIEL",
    subtitle: 'fashion episode',
    preview: preview_of_lanvin_132_1,
    imageAfter: of_lanvin_132_1,
    alt: "L'OFFICIEL fashion episode",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'fashion episode',
    imageAfter: of_lanvin_132_2,
    alt: "L'OFFICIEL fashion episode",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
    hiddenPreview: true,
},
  {
    title: "L'OFFICIEL",
    subtitle: 'fashion episode',
    imageAfter: of_lanvin_132_3,
    alt: "L'OFFICIEL fashion episode",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
    hiddenPreview: true,
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'fashion episode',
    imageAfter: of_lanvin_132_4,
    alt: "L'OFFICIEL fashion episode",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
    hiddenPreview: true,
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'fashion episode',
    imageAfter: of_lanvin_132_5,
    alt: "L'OFFICIEL fashion episode",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
    hiddenPreview: true,
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'fashion episode',
    imageAfter: of_lanvin_132_6,
    alt: "L'OFFICIEL fashion episode",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
    hiddenPreview: true,
  },
  {
    title: "L'OFFICIEL",
    subtitle: 'fashion episode',
    imageAfter: of_lanvin_132_7,
    alt: "L'OFFICIEL fashion episode",
    tags: ["L'OFFICIEL", 'style', 'fashion'],
    hiddenPreview: true,
  },
];

export default OFF_LANVIN_DATA;

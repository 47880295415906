import newsExplorerImagePath from '../images/web-projects/newsExplorer-main.jpg';
import russianTravelImagePath from '../images/web-projects/RussianTravel.jpg';
import howToLearnImagePath from '../images/web-projects/HowToLearn.jpg';
import mestoReactFrontImagePath from '../images/web-projects/MestoReactFront.jpg';
import nkvasovPath from '../images/web-projects/MySite.jpg';
import funboxPath from '../images/web-projects/Funbox-test.jpg';

export const cardsData = [
  {
    title: 'Тест для FUNBOX',
    title1: 'Тестовое задание Funbox',
    description:
      '',
    link: 'https://nkvasov.github.io/funbox-test/',
    gitLink: 'https://github.com/nkvasov/funbox-test',
    linkText: 'nkvasov.github.io/funbox-test/',
    technologiesList: 'JavaScript, React, HTML / CSS',
    technologies: [
      {
        title: 'frontend',
        list: [
          'HTML/CSS',
          'React (+ react-dom, react-router)',
          'JavaScript (ES6)',
        ],
      }
    ],
    features:
      '',
    imagePath: funboxPath,
  },
  {
    title: 'News Explorer',
    title1: 'Дипломный проект в Яндекс Практикуме',
    description:
      'NewsExplorer – одностраничное приложение для поиска новостей по ключевому слову.  Для регистрации и авторизации пользователя создан свой API. Реализована возможность сохранения выбранных новостей. Для поиска новостей используется сторонний API.',
    link: 'https://www.news-explorer.nkvasov.ru/',
    gitLink: 'https://github.com/nkvasov/news-explorer-frontend',
    linkText: 'www.news-explorer.nkvasov.ru',
    technologiesList: 'JavaScript, React, Node/Express, MongoDB',
    technologies: [
      {
        title: 'frontend',
        list: [
          'HTML/CSS',
          'React (+ react-dom, react-router)',
          'JavaScript (ES6)',
        ],
      },
      {
        title: 'backend',
        list: ['Node.js/Express.js', 'MongoDB', 'Node/Express libraries'],
      },
    ],
    features:
      'Проект адаптирован под различные устройства (от 320px по ширине). Для HTML, CSS и файловой структуры используется методология БЭМ.',
    imagePath: newsExplorerImagePath,
  },
  {
    title: 'Портфолио',
    title1: '',
    description: '',
    link: 'https://www.nkvasov.ru/',
    gitLink: 'https://github.com/nkvasov/',
    linkText: 'www.nkvasov.ru',
    technologiesList: 'JavaScript, React',
    technologies: [
      {
        title: 'frontend',
        list: [
          'HTML/CSS',
          'React (+ react-dom, react-router)',
          'JavaScript (ES6)',
        ],
      },
    ],
    features: '',
    imagePath: nkvasovPath,
  },
  {
    title: 'Russian Travel',
    title1: 'Третий проект в Яндекс Практикуме',
    description: 'Страничка о путешествиях по России.',
    link: 'https://nkvasov.github.io/russian-travel/',
    gitLink: 'https://github.com/nkvasov/russian-travel',
    linkText: 'www.russian-travel',
    technologiesList: 'HTML 5.0, CSS 3.0 (grid, flexbox)',
    technologies: [{ title: 'frontend', list: ['HTML/CSS'] }],
    features:
      'Проект адаптирован под различные устройства (от 320px по ширине). Для HTML, CSS и файловой структуры используется методология БЭМ.',
    imagePath: russianTravelImagePath,
  },
  {
    title: 'Место',
    title1: 'Десятый проект в Яндекс Практикуме',
    description:
      'Одностраничная пользовательская галерея, реализованная с помощь библиотеки React. Галерея генерируется на основе данных, запрашиваемых с сервера.',
    link: 'https://www.mesto.nkvasov.ru/',
    gitLink: 'https://github.com/nkvasov/react-mesto-api-full',
    linkText: 'www.mesto.nkvasov.ru',
    technologiesList: 'React, JavaScript, Node/Express, MongoDB',
    technologies: [
      {
        title: 'frontend',
        list: [
          'HTML/CSS',
          'React (+ react-dom, react-router)',
          'JavaScript (ES6)',
        ],
      },
    ],
    features:
      'Проект адаптирован под различные устройства (от 320px по ширине). Для HTML, CSS и файловой структуры используется методология БЭМ.',
    imagePath: mestoReactFrontImagePath,
  },
  {
    title: 'Научиться учиться',
    title1: 'Второй проект в Яндекс Практикуме',
    description:
      'Страничка с вертикальной прокруткой. Посвящена современным подходам к обучению.',
    link: 'https://nkvasov.github.io/how-to-learn/',
    gitLink: 'https://github.com/nkvasov/how-to-learn',
    linkText: 'www.how-to-learn',
    technologiesList: 'HTML 5.0, CSS 3.0 (flexbox)',
    technologies: [{ title: 'frontend', list: ['HTML/CSS'] }],
    features:
      'Макет сверстан с использованием HTML5 и CSS3. Соблюдена семантика HTML. Активно используется CSS Flexbox. Для HTML-элементов, стилей и файловой структуры применена методология БЭМ.',
    imagePath: howToLearnImagePath,
  },
];

// import React, { useState } from 'react';
import './WebCards.css';
import WebCard from '../WebCard/WebCard';
import { cardsData } from '../../utils/webCardsData';

function WebCards() {
  // const [screen, setScreen] = useState({});

  return (
    <section className="web-cards">
      <h2 className="web-cards__title">Проекты</h2>
      {/* <button
        onClick={() => {
          setScreen((prev) => {
            return {
              ...prev,
              1: window.screen.width,
              b: document.documentElement.clientWidth,
              3: window.innerWidth,
              4: window.outerWidth,
              5: document.documentElement.scrollWidth,
              6: document.body.clientWidth
            };
          });
        }}
      >
        screen
      </button> */}
      {/* <p>window.screen.width: {screen[1]}</p>
      <p>document.documentElement.clientWidth: {screen.b}</p>
      <p>window.innerWidth: {screen[3]}</p>
      <p>window.outerWidth: {screen[4]}</p>
      <p>document.documentElement.scrollWidth: {screen[5]}</p>
      <p>document.body.clientWidth: {screen[6]}</p> */}

      <ul className="web-cards__list">
        {cardsData.map((card) => (
          <WebCard
            technologiesList={card.technologiesList}
            key={card.link}
            title={card.title}
            description={card.description}
            link={card.link}
            gitLink={card.gitLink}
            linkText={card.linkText}
            technologies={card.technologies}
            features={card.features}
            imagePath={card.imagePath}
          />
        ))}
      </ul>
    </section>
  );
}

export default WebCards;

import p0 from '../images/image-processing/Comdental-paradontology/0.jpg';
import p0p from '../images/image-processing/Comdental-paradontology/0p.jpg';
import p1 from '../images/image-processing/Comdental-paradontology/1.jpg';
import p3 from '../images/image-processing/Comdental-paradontology/3.jpg';
import p4 from '../images/image-processing/Comdental-paradontology/4.jpg';
import p4p from '../images/image-processing/Comdental-paradontology/4p.jpg';
import p5 from '../images/image-processing/Comdental-paradontology/5.jpg';
import p6 from '../images/image-processing/Comdental-paradontology/6.jpg';
import p12 from '../images/image-processing/Comdental-paradontology/12.jpg';
import p13 from '../images/image-processing/Comdental-paradontology/13.jpg';

import s0 from '../images/image-processing/Comdental-short/0.jpg';
import s0p from '../images/image-processing/Comdental-short/0p.jpg';
import s1 from '../images/image-processing/Comdental-short/1.jpg';
import s2 from '../images/image-processing/Comdental-short/2.jpg';
import s3 from '../images/image-processing/Comdental-short/3.jpg';
import s4 from '../images/image-processing/Comdental-short/4.jpg';
import s5 from '../images/image-processing/Comdental-short/5.jpg';
import s6 from '../images/image-processing/Comdental-short/6.jpg';
import s7 from '../images/image-processing/Comdental-short/7.jpg';
import s7p from '../images/image-processing/Comdental-short/7p.jpg';
import s8 from '../images/image-processing/Comdental-short/8.jpg';


import r0 from '../images/image-processing/Comdental-surgery/0.jpg';
import r0p from '../images/image-processing/Comdental-surgery/0p.jpg';
import r1 from '../images/image-processing/Comdental-surgery/1.jpg';
import r2 from '../images/image-processing/Comdental-surgery/2.jpg';
import r3 from '../images/image-processing/Comdental-surgery/3.jpg';
import r4 from '../images/image-processing/Comdental-surgery/4.jpg';
import r5 from '../images/image-processing/Comdental-surgery/5.jpg';
import r5p from '../images/image-processing/Comdental-surgery/5p.jpg';
import r6 from '../images/image-processing/Comdental-surgery/6.jpg';
import r7 from '../images/image-processing/Comdental-surgery/7.jpg';
import r8 from '../images/image-processing/Comdental-surgery/8.jpg';


export const COMDENTAL_SUR_DATA = [
  {
    title: 'Comdental',
    subtitle: 'каталог',
    preview: r0p,
    imageAfter: r0,
    alt: 'Каталог стоматологических инструментов Comdental "Хирургия"',
    tags: ['subject', 'comdental'],
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: r1,
    alt: 'Каталог стоматологических инструментов Comdental "Хирургия"',
    tags: ['subject', 'comdental'],
    hiddenPreview: true,
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: r2,
    alt: 'Каталог стоматологических инструментов Comdental "Хирургия"',
    tags: ['subject', 'comdental'],
    hiddenPreview: true,
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: r3,
    alt: 'Каталог стоматологических инструментов Comdental "Хирургия"',
    tags: ['subject', 'comdental'],
    hiddenPreview: true,
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: r4,
    alt: 'Каталог стоматологических инструментов Comdental "Хирургия"',
    tags: ['subject', 'comdental'],
    hiddenPreview: true,
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: r5,
    preview: r5p,
    alt: 'Каталог стоматологических инструментов Comdental "Хирургия"',
    tags: ['subject', 'comdental'],
    // hiddenPreview: true,
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: r6,
    alt: 'Каталог стоматологических инструментов Comdental "Хирургия"',
    tags: ['subject', 'comdental'],
    hiddenPreview: true,
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: r7,
    alt: 'Каталог стоматологических инструментов Comdental "Хирургия"',
    tags: ['subject', 'comdental'],
    hiddenPreview: true,
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: r8,
    alt: 'Каталог стоматологических инструментов Comdental "Хирургия"',
    tags: ['subject', 'comdental'],
    hiddenPreview: true,
  },
]

export const COMDENTAL_SHORT_DATA = [
  {
    title: 'Comdental',
    subtitle: 'каталог',
    preview: s0p,
    imageAfter: s0,
    alt: 'Каталог стоматологических инструментов Comdental',
    tags: ['subject', 'comdental'],
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: s1,
    alt: 'Каталог стоматологических инструментов Comdental',
    tags: ['subject', 'comdental'],
    hiddenPreview: true,
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: s2,
    alt: 'Каталог стоматологических инструментов Comdental',
    tags: ['subject', 'comdental'],
    hiddenPreview: true,
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: s3,
    alt: 'Каталог стоматологических инструментов Comdental',
    tags: ['subject', 'comdental'],
    hiddenPreview: true,
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: s4,
    alt: 'Каталог стоматологических инструментов Comdental',
    tags: ['subject', 'comdental'],
    hiddenPreview: true,
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: s7,
    preview: s7p,
    alt: 'Каталог стоматологических инструментов Comdental',
    tags: ['subject', 'comdental'],
    // hiddenPreview: true,
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: s5,
    alt: 'Каталог стоматологических инструментов Comdental',
    tags: ['subject', 'comdental'],
    hiddenPreview: true,
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: s6,
    alt: 'Каталог стоматологических инструментов Comdental',
    tags: ['subject', 'comdental'],
    hiddenPreview: true,
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: s8,
    alt: 'Каталог стоматологических инструментов Comdental',
    tags: ['subject', 'comdental'],
    hiddenPreview: true,
  },
]

export const COMDENTAL_PARADONT_DATA = [
  {
    title: 'Comdental',
    subtitle: 'каталог',
    preview: p0p,
    imageAfter: p0,
    alt: 'Каталог инструментов Comdental "Парадонтология"',
    tags: ['subject', 'comdental', 'style'],
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: p1,
    alt: 'Каталог инструментов Comdental "Парадонтология"',
    tags: ['subject', 'comdental', 'style'],
    hiddenPreview: true,
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: p3,
    alt: 'Каталог инструментов Comdental "Парадонтология"',
    tags: ['subject', 'comdental', 'style'],
    hiddenPreview: true,
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: p4,
    preview: p4p,
    alt: 'Каталог инструментов Comdental "Парадонтология"',
    tags: ['subject', 'comdental', 'style'],
    // hiddenPreview: true,
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: p5,
    alt: 'Каталог инструментов Comdental "Парадонтология"',
    tags: ['subject', 'comdental', 'style'],
    hiddenPreview: true,
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: p6,
    alt: 'Каталог инструментов Comdental "Парадонтология"',
    tags: ['subject', 'comdental', 'style'],
    hiddenPreview: true,
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: p12,
    alt: 'Каталог инструментов Comdental "Парадонтология"',
    tags: ['subject', 'comdental', 'style'],
    hiddenPreview: true,
  },
  {
    title: 'Comdental',
    subtitle: 'каталог',
    imageAfter: p13,
    alt: 'Каталог инструментов Comdental "Парадонтология"',
    tags: ['subject', 'comdental', 'style'],
    hiddenPreview: true,
  },
  
  
];


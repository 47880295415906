import preview_lodka_cover_10 from '../images/image-processing/covers/lodka_cover_10_preview.jpg';
import lodka_cover_10 from '../images/image-processing/covers/lodka_cover_10.jpg';

import preview_lodka_cover_11 from '../images/image-processing/covers/lodka_cover_11_preview.jpg';
import lodka_cover_11 from '../images/image-processing/covers/lodka_cover_11.jpg';

import preview_lodka_cover_12 from '../images/image-processing/covers/lodka_cover_12_preview.jpg';
import lodka_cover_12 from '../images/image-processing/covers/lodka_cover_12.jpg';

import preview_lodka_cover_13 from '../images/image-processing/covers/lodka_cover_13_preview.jpg';
import lodka_cover_13 from '../images/image-processing/covers/lodka_cover_13.jpg';

import preview_lodka_cover_14 from '../images/image-processing/covers/lodka_cover_14_preview.jpg';
import lodka_cover_14 from '../images/image-processing/covers/lodka_cover_14.jpg';

import preview_lodka_cover_15 from '../images/image-processing/covers/lodka_cover_15_preview.jpg';
import lodka_cover_15 from '../images/image-processing/covers/lodka_cover_15.jpg';

import preview_lodka_cover_16 from '../images/image-processing/covers/lodka_cover_16_preview.jpg';
import lodka_cover_16 from '../images/image-processing/covers/lodka_cover_16.jpg';

import preview_lodka_cover_17 from '../images/image-processing/covers/lodka_cover_17_preview.jpg';
import lodka_cover_17 from '../images/image-processing/covers/lodka_cover_17.jpg';

import preview_lodka_cover_18 from '../images/image-processing/covers/lodka_cover_18_preview.jpg';
import lodka_cover_18 from '../images/image-processing/covers/lodka_cover_18.jpg';

import preview_lodka_cover_19 from '../images/image-processing/covers/lodka_cover_19_preview.jpg';
import lodka_cover_19 from '../images/image-processing/covers/lodka_cover_19.jpg';

import preview_lodka_cover_20 from '../images/image-processing/covers/lodka_cover_20_preview.jpg';
import lodka_cover_20 from '../images/image-processing/covers/lodka_cover_20.jpg';

const LODKA_COVERS_DATA = [
  {
    title: 'ЛОДКА',
    subtitle: 'magazine',
    preview: preview_lodka_cover_10,
    imageAfter: lodka_cover_10,
    alt: 'обложка журнала "ЛОДКА" #10',
    tags: ['cover', 'lodka'],
  },
  {
    title: 'ЛОДКА',
    subtitle: 'magazine',
    preview: preview_lodka_cover_11,
    imageAfter: lodka_cover_11,
    alt: 'обложка журнала "ЛОДКА" #11',
    tags: ['cover', 'lodka'],
  },
  {
    title: 'ЛОДКА',
    subtitle: 'magazine',
    preview: preview_lodka_cover_12,
    imageAfter: lodka_cover_12,
    alt: 'обложка журнала "ЛОДКА" #12',
    tags: ['cover', 'lodka'],
  },
  {
    title: 'ЛОДКА',
    subtitle: 'magazine',
    preview: preview_lodka_cover_13,
    imageAfter: lodka_cover_13,
    alt: 'обложка журнала "ЛОДКА" #13',
    tags: ['cover', 'lodka'],
  },
  {
    title: 'ЛОДКА',
    subtitle: 'magazine',
    preview: preview_lodka_cover_14,
    imageAfter: lodka_cover_14,
    alt: 'обложка журнала "ЛОДКА" #14',
    tags: ['cover', 'lodka'],
  },
  {
    title: 'ЛОДКА',
    subtitle: 'magazine',
    preview: preview_lodka_cover_15,
    imageAfter: lodka_cover_15,
    alt: 'обложка журнала "ЛОДКА" #15',
    tags: ['cover', 'lodka'],
  },
  {
    title: 'ЛОДКА',
    subtitle: 'magazine',
    preview: preview_lodka_cover_16,
    imageAfter: lodka_cover_16,
    alt: 'обложка журнала "ЛОДКА" #16',
    tags: ['cover', 'lodka'],
  },
  {
    title: 'ЛОДКА',
    subtitle: 'magazine',
    preview: preview_lodka_cover_17,
    imageAfter: lodka_cover_17,
    alt: 'обложка журнала "ЛОДКА" #17',
    tags: ['cover', 'lodka'],
  },
  {
    title: 'ЛОДКА',
    subtitle: 'magazine',
    preview: preview_lodka_cover_18,
    imageAfter: lodka_cover_18,
    alt: 'обложка журнала "ЛОДКА" #18',
    tags: ['cover', 'lodka'],
  },
  {
    title: 'ЛОДКА',
    subtitle: 'magazine',
    preview: preview_lodka_cover_19,
    imageAfter: lodka_cover_19,
    alt: 'обложка журнала "ЛОДКА" #19',
    tags: ['cover', 'lodka'],
  },
  {
    title: 'ЛОДКА',
    subtitle: 'magazine',
    preview: preview_lodka_cover_20,
    imageAfter: lodka_cover_20,
    alt: 'обложка журнала "ЛОДКА" #20',
    tags: ['cover', 'lodka'],
  },
];

export default LODKA_COVERS_DATA;

import preview_vld_afimall_01 from '../images/image-processing/vlad/vld-afimall_01_preview.jpg';
import preview_vld_afimall_02 from '../images/image-processing/vlad/vld-afimall_02_preview.jpg';
import preview_vld_afimall_03 from '../images/image-processing/vlad/vld-afimall_03_preview.jpg';
import preview_vld_afimall_04 from '../images/image-processing/vlad/vld-afimall_04_preview.jpg';
import preview_vld_afimall_05 from '../images/image-processing/vlad/vld-afimall_05_preview.jpg';
import preview_vld_afimall_06 from '../images/image-processing/vlad/vld-afimall_06_preview.jpg';
import preview_vld_afimall_07 from '../images/image-processing/vlad/vld-afimall_07_preview.jpg';
import preview_vld_afimall_08 from '../images/image-processing/vlad/vld-afimall_08_preview.jpg';
import preview_vld_afimall_09 from '../images/image-processing/vlad/vld-afimall_09_preview.jpg';
import vld_afimall_01 from '../images/image-processing/vlad/vld-afimall_01.jpg';
import vld_afimall_02 from '../images/image-processing/vlad/vld-afimall_02.jpg';
import vld_afimall_03 from '../images/image-processing/vlad/vld-afimall_03.jpg';
import vld_afimall_04 from '../images/image-processing/vlad/vld-afimall_04.jpg';
import vld_afimall_05 from '../images/image-processing/vlad/vld-afimall_05.jpg';
import vld_afimall_06 from '../images/image-processing/vlad/vld-afimall_06.jpg';
import vld_afimall_07 from '../images/image-processing/vlad/vld-afimall_07.jpg';
import vld_afimall_08 from '../images/image-processing/vlad/vld-afimall_08.jpg';
import vld_afimall_09 from '../images/image-processing/vlad/vld-afimall_09.jpg';
// hiddenPreview: true,

const VLAD_AFIMALL_DATA = [
  //  vlad afimall
  {
    title: 'Afimall',
    subtitle: 'Влад Антонов',
    preview: preview_vld_afimall_01,
    imageAfter: vld_afimall_01,
    alt: 'предметная съемка для Афимолл',
    tags: ['subject', 'vlad'],
  },
  {
    title: 'Afimall',
    subtitle: 'Влад Антонов',
    preview: preview_vld_afimall_02,
    imageAfter: vld_afimall_02,
    alt: 'предметная съемка для Афимолл',
    tags: ['subject', 'vlad'],
  },
  {
    title: 'Afimall',
    subtitle: 'Влад Антонов',
    preview: preview_vld_afimall_03,
    imageAfter: vld_afimall_03,
    alt: 'предметная съемка для Афимолл',
    tags: ['subject', 'vlad'],
  },
  {
    title: 'Afimall',
    subtitle: 'Влад Антонов',
    preview: preview_vld_afimall_08,
    imageAfter: vld_afimall_08,
    alt: 'предметная съемка для Афимолл',
    tags: ['subject', 'vlad'],
  },
  {
    title: 'Afimall',
    subtitle: 'Влад Антонов',
    preview: preview_vld_afimall_04,
    imageAfter: vld_afimall_04,
    alt: 'предметная съемка для Афимолл',
    tags: ['subject', 'vlad'],
  },
  {
    title: 'Afimall',
    subtitle: 'Влад Антонов',
    preview: preview_vld_afimall_09,
    imageAfter: vld_afimall_09,
    alt: 'предметная съемка для Афимолл',
    tags: ['subject', 'vlad'],
  },
  {
    title: 'Afimall',
    subtitle: 'Влад Антонов',
    preview: preview_vld_afimall_05,
    imageAfter: vld_afimall_05,
    alt: 'предметная съемка для Афимолл',
    tags: ['subject', 'vlad'],
    hiddenPreview: true,
  },
  {
    title: 'Afimall',
    subtitle: 'Влад Антонов',
    preview: preview_vld_afimall_06,
    imageAfter: vld_afimall_06,
    alt: 'предметная съемка для Афимолл',
    tags: ['subject', 'vlad'],
    hiddenPreview: true,
  },
  {
    title: 'Afimall',
    subtitle: 'Влад Антонов',
    preview: preview_vld_afimall_07,
    imageAfter: vld_afimall_07,
    alt: 'предметная съемка для Афимолл',
    tags: ['subject', 'vlad'],
    hiddenPreview: true,
  },
];

export default VLAD_AFIMALL_DATA;

import preview_mr_cover_1 from '../images/image-processing/covers/mr_cover_1_preview.jpg';
import preview_mr_cover_2 from '../images/image-processing/covers/mr_cover_2_preview.jpg';
import preview_mr_cover_3 from '../images/image-processing/covers/mr_cover_3_preview.jpg';
import preview_mr_cover_4 from '../images/image-processing/covers/mr_cover_4_preview.jpg';
import mr_cover_1 from '../images/image-processing/covers/mr_cover_1.jpg';
import mr_cover_2 from '../images/image-processing/covers/mr_cover_2.jpg';
import mr_cover_3 from '../images/image-processing/covers/mr_cover_3.jpg';
import mr_cover_4 from '../images/image-processing/covers/mr_cover_4.jpg';


const MR_COVERS_DATA = [
  {
    title: 'Moulin Rouge',
    subtitle: 'magazine',
    preview: preview_mr_cover_1,
    imageAfter: mr_cover_1,
    alt: 'обложка журнала "Moulin Rouge" #9 2004',
    tags: ['cover'],
  },
  {
    title: 'Moulin Rouge',
    subtitle: 'magazine',
    preview: preview_mr_cover_2,
    imageAfter: mr_cover_2,
    alt: 'обложка журнала "Moulin Rouge" #11 2004',
    tags: ['cover'],
  },
  {
    title: 'Moulin Rouge',
    subtitle: 'magazine',
    preview: preview_mr_cover_3,
    imageAfter: mr_cover_3,
    alt: 'обложка журнала "Moulin Rouge" #1 2005',
    tags: ['cover'],
  },
  {
    title: 'Moulin Rouge',
    subtitle: 'magazine',
    preview: preview_mr_cover_4,
    imageAfter: mr_cover_4,
    alt: 'обложка журнала "Moulin Rouge" #2 2005',
    tags: ['cover'],
  },
];

export default MR_COVERS_DATA;


import preview_dom_cover_1 from '../images/image-processing/covers/dom_cover_1_preview.jpg';
import after_dom_cover_1 from '../images/image-processing/covers/dom_cover_1.jpg';

import preview_dom_cover_2 from '../images/image-processing/covers/dom_cover_2_preview.jpg';
import after_dom_cover_2 from '../images/image-processing/covers/dom_cover_2.jpg';

import preview_dom_cover_3 from '../images/image-processing/preview_15.jpg';
import after_dom_cover_3 from '../images/image-processing/after_15.jpg';

import preview_flex_cover_1 from '../images/image-processing/covers/flex_cover_1_preview.jpg';
import after_flex_cover_1 from '../images/image-processing/covers/flex_cover_1.jpg';

import preview_shape_cover_1 from '../images/image-processing/preview_12.jpg';
import after_shape_cover_1 from '../images/image-processing/after_12.jpg';


const COVERS_DATA = [
  {
    title: 'ДОМОВОЙ',
    subtitle: 'magazine',
    preview: preview_dom_cover_1,
    imageAfter: after_dom_cover_1,
    alt: 'обложка журнала "ДОМОВОЙ" #2 2007',
    tags: ['cover'],
  },
  {
    title: 'ДОМОВОЙ',
    subtitle: 'magazine',
    preview: preview_dom_cover_2,
    imageAfter: after_dom_cover_2,
    alt: 'обложка журнала "ДОМОВОЙ" #2 2006',
    tags: ['cover'],
  },
  {
    title: 'ДОМОВОЙ',
    subtitle: 'magazine',
    preview: preview_dom_cover_3,
    imageAfter: after_dom_cover_3,
    alt: 'обложка журнала "ДОМОВОЙ" #11 2006',
    tags: ['cover'],
  },
  {
    title: 'FLEX',
    subtitle: 'magazine',
    preview: preview_flex_cover_1,
    imageAfter: after_flex_cover_1,
    alt: 'обложка журнала "FLEX" #8 2015',
    tags: ['cover'],
  },
  {
    title: 'SHAPE',
    subtitle: 'magazine',
    preview: preview_shape_cover_1,
    imageAfter: after_shape_cover_1,
    alt: 'обложка журнала "SHAPE" #2 2018',
    tags: ['cover'],
  },
];

export default COVERS_DATA;

import React from 'react';
import { useLocation } from 'react-router-dom';
import './Footer.css';
import SocialBlock from '../SocialBlock/SocialBlock';
import cn from 'classnames';

const Footer = () => {
  const location = useLocation();
  const footerClass = cn('footer', {
    footer_hidden: location.pathname === '/',
  });

  return (
    <footer className={footerClass}>
      <p className="footer__copyright">&copy; 2021 Nikolay Kvasov</p>
      <SocialBlock iconSize="25px" />
    </footer>
  );
};

export default Footer;

import MR_COVERS_DATA from './MR_COVERS_DATA';
import OFF_COVERS_DATA from './OFF_COVERS_DATA';
import COVERS_DATA from './COVERS_DATA';
import CEO_COVERS_DATA from './CEO_COVERS_DATA';
import LODKA_COVERS_DATA from './LODKA_COVERS_DATA';
import { DOMOVOY_PLASTIC, DOMOVOY_PLASTIC1, DOMOVOY_LAK } from './DOMOVOY_DATA';

import OFF_BEAUTY_DATA from './OFF_BEAUTY_DATA';
import OFF_WHATCHES_DATA from './OFF_WHATCHES_DATA';
import OFF_FASHION_DATA from './OFF_FASHION_DATA';
import OFF_CIRCUS_DATA from './OFF_CIRCUS_DATA';
import OFF_LANVIN_DATA from './OFF_LANVIN_DATA';
import OFF_UFO_DATA from './OFF_UFO_DATA';

import VLAD_HOLLAND_DATA from './VLAD_HOLLAND_DATA';
import VLAD_AFIMALL_DATA from './VLAD_AFIMALL_DATA';

import CEO_COLLAGES_DATA from './CEO_COLLAGES_DATA';
import {
  CEO_SHOES_DATA,
  CEO_CHICHVARKIN_DATA,
  CEO_WHATCHES_DATA,
} from './CEO_PAGES_DATA';

import INITIAL_IMAGES_DATA from './INITIAL_IMAGES_DATA';
import { CHAPURIN_DATA } from './CHAPURIN_DATA';
import { COMDENTAL_PARADONT_DATA, COMDENTAL_SHORT_DATA, COMDENTAL_SUR_DATA } from './COMDENTAL_CATALOGUES';

export const imagesDoneData = [
  ...MR_COVERS_DATA,

  ...COVERS_DATA,

  ...LODKA_COVERS_DATA,

  ...CEO_CHICHVARKIN_DATA,
  ...CEO_SHOES_DATA,
  ...CEO_COLLAGES_DATA,
  ...CEO_WHATCHES_DATA,
  ...CEO_COVERS_DATA,

  ...OFF_WHATCHES_DATA,
  // Officiel beauty pages
  ...OFF_BEAUTY_DATA,
  ...OFF_UFO_DATA,
  ...OFF_LANVIN_DATA,
  ...OFF_CIRCUS_DATA,
  ...OFF_FASHION_DATA,
  ...OFF_COVERS_DATA,
  
  ...DOMOVOY_PLASTIC,
  ...DOMOVOY_PLASTIC1,
  ...DOMOVOY_LAK,

  ...VLAD_AFIMALL_DATA,
  
  ...CHAPURIN_DATA,

  ...VLAD_HOLLAND_DATA,

  ...INITIAL_IMAGES_DATA,

  ...COMDENTAL_PARADONT_DATA,
  ...COMDENTAL_SHORT_DATA,
  ...COMDENTAL_SUR_DATA,
];

import React, { useState } from 'react';
import { Transition } from 'react-transition-group';

import './Popup.css';
import cn from 'classnames';
import ModalNavBtn from '../UI/ModalNavBtn';

const Popup = ({ imageData, isOpened, onClose, previousImage, nextImage }) => {
  const [originalIsHidden, setOriginalIsHidden] = useState(true);
  const [inProp, setInProp] = useState(true);
  const [flipDirection, setFlipDirection] = useState('next');
  const [swipeStartX, setSwipeStartX] = useState(null);
  const [swipeStarted, setSwipeStarted] = useState(false);
  const popupClass = cn('popup', { popup_opened: isOpened });

  const toggleImage = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (imageData.imageBefore) {
      setOriginalIsHidden(!originalIsHidden);
    }
  };

  const detectScaling = () => {
    const scaleFactor = window.outerWidth / window.innerWidth;
    return scaleFactor > 1.1;
  };

  const touchStartHandler = (e) => {
    if (e.touches.length !== 1 || swipeStarted || detectScaling()) {
      return;
    }
    const touch = e.changedTouches[0];
    setSwipeStartX(touch.pageX);
  };

  const touchMoveHandler = (e) => {
    if (
      !detectScaling() &&
      !swipeStarted &&
      e.touches.length === 1 &&
      Math.abs(e.changedTouches[0].pageX - swipeStartX) > 25
    ) {
      e.preventDefault();
      setSwipeStarted(true);
    } else if (swipeStarted) {
      e.preventDefault();
      let image = e.target;
      const deltaX = e.changedTouches[0].pageX - swipeStartX;
      image.style.left = `calc(50% + ${deltaX}px)`;
    }
  };

  const touchEndHandler = (e) => {
    if (!swipeStarted) {
      return;
    }

    const touch = e.changedTouches[0];
    const xEnd = touch.pageX;

    if (xEnd > swipeStartX) {
      e.preventDefault();
      setInProp(false);
      if (flipDirection === 'next') {
        setFlipDirection('previous');
      }
      e.target.style.left = '';
      setSwipeStarted(false);
      setOriginalIsHidden(true);
    } else if (xEnd < swipeStartX) {
      e.preventDefault();
      setInProp(false);
      if (flipDirection === 'previous') {
        setFlipDirection('next');
      }
      e.target.style.left = '';
      setSwipeStarted(false);
      setOriginalIsHidden(true);
    } else {
      return;
    }
  };

  return (
    <section
      className={popupClass}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="popup__description">
        <h2 className="popup__title">{imageData.title}</h2>
        <p className="popup__subtitle">{imageData.subtitle}</p>
      </div>

      <Transition
        mountOnEnter
        unmountOnExit
        in={inProp}
        timeout={{ enter: 300, exit: 400 }}
        onExited={() => {
          if (flipDirection === 'previous') {
            previousImage();
          } else {
            nextImage();
          }
          setInProp(true);
        }}
      >
        {(state) => {
          const transitionClassnames = cn(
            'popup__image',
            {
              popup__image_shift_left:
                state === 'exiting' && flipDirection === 'next',
            },
            {
              popup__image_shift_right:
                state === 'exiting' && flipDirection === 'previous',
            }
          );
          return (
            <>
              {imageData.imageBefore && (
                <img
                  className={cn(
                    { popup__image_hidden: originalIsHidden },
                    transitionClassnames
                  )}
                  src={imageData.imageBefore}
                  alt={imageData.alt}
                />
              )}
              <img
                className={cn(
                  { popup__image_hidden: !originalIsHidden },
                  transitionClassnames
                )}
                src={imageData.imageAfter}
                alt={imageData.alt}
                onMouseDown={toggleImage}
                onTouchStart={touchStartHandler}
                onTouchMove={touchMoveHandler}
                onTouchEnd={touchEndHandler}
              />
            </>
          );
        }}
      </Transition>
      <button
        className="popup__close-btn"
        type="button"
        name="close"
        onClick={onClose}
        aria-label="Закрыть попап"
      />
      <ModalNavBtn
        direction="left"
        onClick={() => {
          if (flipDirection === 'next') {
            setFlipDirection('previous');
          }
          setInProp(false);
          setOriginalIsHidden(true);
        }}
        aria-label="Предыдущая картинка"
        name="previous-image"
      />

      <ModalNavBtn
        name="next-image"
        aria-label="Следующая картинка"
        onClick={() => {
          if (flipDirection === 'previous') {
            setFlipDirection('next');
          }
          setInProp(false);
          setOriginalIsHidden(true);
        }}
      />

      {imageData.imageBefore && (
        <div className="popup__btn-container">
          <button
            className="popup__regular-btn"
            type="button"
            name="toggle-image"
            aria-label="Показать картинку до обработки"
            onClick={toggleImage}
          >
            {originalIsHidden ? 'Стало' : 'Было'}
          </button>
        </div>
      )}
    </section>
  );
};
export default Popup;

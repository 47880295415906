// import peview_ceo_pages_4 from '../images/image-processing/CEO-pages/ceo_pages_4_preview.jpg';
// import after_ceo_pages_4 from '../images/image-processing/CEO-pages/ceo_pages_4.jpg';

// import peview_ceo_pages_5 from '../images/image-processing/CEO-pages/ceo_pages_5_preview.jpg';
// import after_ceo_pages_5 from '../images/image-processing/CEO-pages/ceo_pages_5.jpg';

import peview_ceo_pages_6 from '../images/image-processing/CEO-pages/ceo_pages_6_preview.jpg';
import after_ceo_pages_6 from '../images/image-processing/CEO-pages/ceo_pages_6.jpg';

import peview_ceo_pages_7 from '../images/image-processing/CEO-pages/ceo_pages_7_preview.jpg';
import after_ceo_pages_7 from '../images/image-processing/CEO-pages/ceo_pages_7.jpg';

// import peview_ceo_pages_8 from '../images/image-processing/CEO-pages/ceo_pages_8_preview.jpg';
import after_ceo_pages_8 from '../images/image-processing/CEO-pages/ceo_pages_8.jpg';

// import peview_ceo_pages_9 from '../images/image-processing/CEO-pages/ceo_pages_9_preview.jpg';
import after_ceo_pages_9 from '../images/image-processing/CEO-pages/ceo_pages_9.jpg';

import peview_ceo_pages_10 from '../images/image-processing/CEO-pages/ceo_pages_10_preview.jpg';
import after_ceo_pages_10 from '../images/image-processing/CEO-pages/ceo_pages_10.jpg';

// import peview_ceo_pages_11 from '../images/image-processing/CEO-pages/ceo_pages_11_preview.jpg';
import after_ceo_pages_11 from '../images/image-processing/CEO-pages/ceo_pages_11.jpg';

import after_ceo_pages_13 from '../images/image-processing/after_13.jpg';
import after_ceo_pages_14 from '../images/image-processing/after_14.jpg';

import peview_ceo_pages_13 from '../images/image-processing/preview_13.jpg';
// import peview_ceo_pages_14 from '../images/image-processing/preview_14.jpg';


const CEO_COLLAGES_DATA = [
  // {
  //   title: 'CEO',
  //   subtitle: 'magazine',
  //   preview: peview_ceo_pages_4,
  //   imageAfter: after_ceo_pages_4,
  //   alt: 'страницы журнала CEO #5 2010',
  //   tags: ['CEO', 'collage'],
  // },
  // {
  //   title: 'CEO',
  //   subtitle: 'magazine',
  //   preview: peview_ceo_pages_5,
  //   imageAfter: after_ceo_pages_5,
  //   alt: 'страницы журнала CEO #5 2010',
  //   tags: ['CEO', 'collage'],
  //   hiddenPreview: true,
  // },
  {
    title: 'CEO',
    subtitle: 'style',
    preview: peview_ceo_pages_6,
    imageAfter: after_ceo_pages_6,
    alt: 'страницы журнала CEO #5 2010',
    tags: ['CEO', 'collage', 'style'],
  },
  {
    title: 'CEO',
    subtitle: 'style',
    // preview: peview_ceo_pages_9,
    imageAfter: after_ceo_pages_9,
    alt: 'коллаж журнала CEO',
    tags: ['CEO', 'collage', 'style'],
    hiddenPreview: true,
  },
  {
    title: 'CEO',
    subtitle: 'magazine',
    preview: peview_ceo_pages_7,
    imageAfter: after_ceo_pages_7,
    alt: 'коллаж авто журнала CEO',
    tags: ['CEO', 'collage'],
  },
  {
    title: 'CEO',
    subtitle: 'magazine',
    // preview: peview_ceo_pages_8,
    imageAfter: after_ceo_pages_8,
    alt: 'коллаж авто журнала CEO',
    tags: ['CEO', 'collage'],
    hiddenPreview: true,
  },
  {
    title: 'CEO',
    subtitle: 'magazine',
    preview: peview_ceo_pages_10,
    imageAfter: after_ceo_pages_10,
    alt: 'коллаж авто журнала CEO',
    tags: ['CEO', 'collage'],
    // hiddenPreview: true,
  },
  {
    title: 'CEO',
    subtitle: 'magazine',
    // preview: peview_ceo_pages_11,
    imageAfter: after_ceo_pages_11,
    alt: 'коллаж авто журнала CEO',
    tags: ['CEO', 'collage'],
    hiddenPreview: true,
  },
  {
    title: 'CEO',
    subtitle: 'magazine',
    preview: peview_ceo_pages_13,
    imageAfter: after_ceo_pages_13,
    alt: 'коллаж авто журнала CEO',
    tags: ['CEO', 'collage'],
  },
  {
    title: 'CEO',
    subtitle: 'magazine',
    // preview: peview_ceo_pages_14,
    imageAfter: after_ceo_pages_14,
    alt: 'коллаж авто журнала CEO',
    tags: ['CEO', 'collage'],
    hiddenPreview: true,
  },
];

export default CEO_COLLAGES_DATA;

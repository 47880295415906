import React from 'react';

import cn from 'classnames';
import './ModalNavBtn.css';
import NextIcon from './NextIcon';
import PrevIcon from './PrevIcon';

const ModalNavBtn = ({ direction = 'right', className, ...props }) => {
  const buttonClass = cn(
    'modal-nav-btn',
    { 'modal-nav-btn_right': direction === 'right' },
    { 'modal-nav-btn_left': direction === 'left' },
    className
  );
  return (
    <button className={buttonClass} type="button" {...props}>
      {direction === 'right' && <NextIcon />}
      {direction === 'left' && <PrevIcon />}
    </button>
  );
};

export default ModalNavBtn;

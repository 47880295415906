import preview_ceo_pages_1 from '../images/image-processing/CEO-pages/ceo_pages_1_preview.jpg';

import ceo_pages_1 from '../images/image-processing/CEO-pages/ceo_pages_1.jpg';
import ceo_pages_2 from '../images/image-processing/CEO-pages/ceo_pages_2.jpg';
import ceo_pages_3 from '../images/image-processing/CEO-pages/ceo_pages_3.jpg';

import ceo_person_1_1 from '../images/image-processing/CEO-persons/ceo_person_1-1.jpg';
import preview_ceo_person_1 from '../images/image-processing/CEO-persons/ceo_person_1_preview.jpg';
import ceo_person_1_2 from '../images/image-processing/CEO-persons/ceo_person_1-2.jpg';
import ceo_person_1_3 from '../images/image-processing/CEO-persons/ceo_person_1-3.jpg';

import w1 from '../images/image-processing/CEO-whatches-salon/1.jpg';
import w2 from '../images/image-processing/CEO-whatches-salon/2.jpg';
import w3 from '../images/image-processing/CEO-whatches-salon/3.jpg';
import w3p from '../images/image-processing/CEO-whatches-salon/3p.jpg';


export const CEO_WHATCHES_DATA = [
  // салон часов ------------
  {
    title: 'CEO',
    subtitle: 'часовой салон',
    preview: w3p,
    imageAfter: w1,
    alt: 'страницы журнала CEO – коллаж по мотивам часового салона',
    tags: ['CEO', 'collage', 'style'],
  },
  {
    title: 'CEO',
    subtitle: 'часовой салон',
    imageAfter: w2,
    alt: 'страницы журнала CEO – коллаж по мотивам часового салона',
    tags: ['CEO', 'collage', 'style'],
    hiddenPreview: true,
  },
  {
    title: 'CEO',
    subtitle: 'часовой салон',
    imageAfter: w3,
    alt: 'страницы журнала CEO – коллаж по мотивам часового салона',
    tags: ['CEO', 'collage', 'style'],
    hiddenPreview: true,
  },
  
];

export const CEO_CHICHVARKIN_DATA = [
  // чичаркин ------------
  {
    title: 'CEO',
    subtitle: 'person',
    preview: preview_ceo_person_1,
    imageAfter: ceo_person_1_1,
    alt: 'страницы журнала CEO #11 2012 – Евгений Чичваркин',
    tags: ['CEO'],
  },
  {
    title: 'CEO',
    subtitle: 'person',
    imageAfter: ceo_person_1_2,
    alt: 'страницы журнала CEO #11 2012 – Евгений Чичваркин',
    tags: ['CEO'],
    hiddenPreview: true,
  },
  {
    title: 'CEO',
    subtitle: 'person',
    imageAfter: ceo_person_1_3,
    alt: 'страницы журнала CEO #11 2012 – Евгений Чичваркин',
    tags: ['CEO'],
    hiddenPreview: true,
  },
];


export const CEO_SHOES_DATA = [
  // ботинки ------------
  {
    title: 'CEO',
    subtitle: 'shoes shooting',
    preview: preview_ceo_pages_1,
    imageAfter: ceo_pages_1,
    alt: 'страницы журнала CEO #5 2010',
    tags: ['CEO', 'style'],
  },
  {
    title: 'CEO',
    subtitle: 'shoes shooting',
    // preview: preview_ceo_pages_2,
    imageAfter: ceo_pages_2,
    alt: 'страницы журнала CEO #5 2010',
    tags: ['CEO', 'style'],
    hiddenPreview: true,
  },
  {
    title: 'CEO',
    subtitle: 'shoes shooting',
    // preview: preview_ceo_pages_3,
    imageAfter: ceo_pages_3,
    alt: 'страницы журнала CEO #5 2010',
    tags: ['CEO', 'style'],
    hiddenPreview: true,
  },
];

import React from 'react';
import './About.css';
import author from '../../images/nkvasov_yandex.jpg';
import Skills from '../Skills/Skills';

function About() {
  return (
    <section className="about">
      <div className="about__top-container">
        <img src={author} className="about__image" alt="фото автора" />
        <div className="about__text-container">
          <h2 className="about__title">Здравствуйте!</h2>
          <p className="about__text">Меня зовут Николай Квасов.</p>
          <p className="about__text"></p>

          <p className="about__text">
            Более 15 лет я занимаюсь допечатной подготовкой и&nbsp;обработкой
            изображений. Работал в&nbsp;глянцевых журналах{' '}
            <span className="about__text-bold">
              L'OFFICIEL, ЛОДКА, Mercedes Club, CEO, FHM, ОНА, SHAPE, Домовой,
              L'OPTIMUM, XXL, Moulin Rouge (MAX), Unique Travel, Jewelry Garden, 
            </span> журнале <span className="about__text-bold">Business Week.</span>{' '}
            {/* Полностью подготовил и сдал в печать более сотни глянцевых журналов. */}
            Сотрудничал с издательствами{' '}
            <span className="about__text-bold">
              Комсомольская Правда, Актион-медиа, Бизнеском, Издательский дом
              Родионова, Burda, Логос, Лингвист, {' '}</span>
            с&nbsp;группой{' '}
            <span className="about__text-bold">Hearst Shkulev Media. </span>{' '}
          </p>
          <p className="about__text">
            Помог десятку арт-директоров реализовать их замыслы. Среди них{' '}
            <span className="about__text-bold">
              Ольга Скорупская, Рома Манихин, Денис Муратов, Андрей Бартенев, Сергей
              Астафуров, Сергей Агронский, Дмитрий Девишвили.
            </span>{' '}
            {/* Работая в&nbsp;ИДР, в&nbsp;течение нескольких лет выполнял самые
            ответственные проекты, например номерные vip-календари{' '}
            <span className="about__text-bold">
              {' '}
              Guido Argentini, H.&nbsp;Newton, Alex Bensimon, Terry Richardson.
            </span>{' '}
            В&nbsp;течение года обрабатывал все съемки для Интернет-проекта{' '}
            <span className="about__text-bold">Poison&nbsp;Drop,</span> более 10
            лет полностью готовлю к печати всю полиграфию для комании{' '}
            <span className="about__text-bold">
              Comdental (бренды Curaprox, HuFriedy, Brumaba, Verdan, Burgener).
            </span>{' '} */}
          </p>
          {/* <p className="about__text"> */}
          {/* Имею опыт создания бесшовных паттернов из сканов высокого разрешения
            (обойная компания SURGAZ). */}
          {/* За время работы отретушировал огромное
            количество материала в жанрах предметной, fashion- и beauty-съемки.​
            Полностью готовил к печати книги, календари, альбомы, каталоги, а
            также выполнял заказы для фотографов. Профессионально владею
            Photoshop и InDesign. */}
          {/* </p> */}
          <p className="about__text">
            С 2020 года в сферу моих интересов добавилась{' '}
            <span className="about__text-bold">веб-разработка.</span> В 2021
            году я окончил 9-месячный курс разработчика в Яндекс-Практикуме. Для
            меня новая профессия – как новая жизнь. Возможно кому-то смена
            профессии в моём случае покажется абсурдом, а моё поведение –
            безрассудным. Но для меня это вызов, и перемены – моё осознанное
            решение.
            <span className="about__text-bold">
              {' '}
              С&nbsp;трепетом готов сменить InDesign и Capture&nbsp;One на React
              и Node.js.
            </span>{' '}
            Мне нравится копаться в коде и&nbsp;доводить его до совершенства.
          </p>
          <p className="about__text">
            Связаться со мной можно любым удобным для вас способом.
          </p>
          <p className="about__text">Всего наилучшего!</p>
        </div>
      </div>
      <h3 className="about__subtitle">Навыки</h3>
      <hr />

      <Skills iconSize="40px" />
    </section>
  );
}

export default About;
